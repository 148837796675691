<template>
  <div style="overflow: scroll; height: 750px">
    <div>
      <p>黄大茶</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/yellow/黄大茶-冲泡1.jpg" />
        <img src="../../assets/teaStandImg/yellow/黄大茶-成品.jpg" />
        <img src="../../assets/teaStandImg/yellow/黄大茶-汤色1.jpeg" />
        <img src="../../assets/teaStandImg/yellow/黄大茶.jpeg" />
      </div>

      <div class="tablecontainer">
        <!-- 黄大茶 -->
        <table>
          <tr>
            <th rowspan="2">黄大茶</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
          </tr>
          <tr>
            <th>形状</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>芽型</td>
            <td>针形或雀舌形</td>
            <td>匀齐</td>
            <td>净</td>
            <td>嫩黄</td>
            <td>清鲜</td>
            <td>鲜醇回甘</td>
            <td>杏黄明亮</td>
            <td>肥嫩黄亮</td>
          </tr>
          <tr>
            <td>芽叶型</td>
            <td>条形或扁形或兰花形</td>
            <td>较匀齐</td>
            <td>净</td>
            <td>黄青</td>
            <td>清高</td>
            <td>醇厚回甘</td>
            <td>黄明亮</td>
            <td>柔嫩黄亮</td>
          </tr>
          <tr>
            <td>多叶型</td>
            <td>卷略松</td>
            <td>尚匀</td>
            <td>有茎梗</td>
            <td>黄褐</td>
            <td>纯正、有锅巴香</td>
            <td>醇和</td>
            <td>深黄明亮</td>
            <td>尚软黄尚亮有茎梗</td>
          </tr>
          <tr>
            <td>紧压型</td>
            <td>规整</td>
            <td>紧实</td>
            <td>——</td>
            <td>褐黄</td>
            <td>醇正</td>
            <td>醇和</td>
            <td>深黄</td>
            <td>尚匀</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>水分/(g/100 g)≤</th>
            <th>总灰分/(g/100 g)≤</th>
            <th>碎茶和粉末(质量分数)/%≤</th>
            <th>水浸出物(质量分数)/%≥</th>
          </tr>
          <tr>
            <td rowspan="2">6.5</td>
            <td rowspan="2">7</td>
            <td>2</td>
            <td rowspan="4">32</td>
          </tr>
          <tr>
            <td>3</td>
          </tr>
          <tr>
            <td>7.5</td>
            <td rowspan="2">7.5</td>
            <td>6</td>
          </tr>
          <tr>
            <td>9</td>
            <td>——</td>
          </tr>
        </table>
      </div>

      <p>霍山黄芽</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/yellow/霍山黄芽.jpeg" />
        <img src="../../assets/teaStandImg/yellow/霍山黄芽-茶叶.jpg" />
        <img src="../../assets/teaStandImg/yellow/霍山黄芽-冲泡.jpg" />
        <img src="../../assets/teaStandImg/yellow/霍山黄芽-汤色.jpeg" />
      </div>

      <!-- 霍山黄芽 -->
      <div class="tablecontainer">
        <table>
          <tr>
            <th rowspan="2">霍山黄芽</th>
            <th>水分/%&lt;</th>
            <th>总灰分/%≤</th>
            <th>碎末茶/%≤</th>
            <th>水浸出物/%≥</th>
            <th>粗纤维/%≤</th>
          </tr>
          <tr>
            <th>6</th>
            <th>7</th>
            <th>6</th>
            <th>32</th>
            <th>16.5</th>
          </tr>
        </table>
      </div>

      <p>君山银针</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/yellow/君山银针-茶叶4.jpg" />
        <img src="../../assets/teaStandImg/yellow/君山银针-产品.jpg" />
        <img src="../../assets/teaStandImg/yellow/君山银针-冲泡3.jpg" />
        <img src="../../assets/teaStandImg/yellow/君山银针-制作.jpg" />
      </div>
      <!-- 君山银针 -->
      <div class="tablecontainer">
        <table>
          <tr>
            <th rowspan="2">君山银针</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
          </tr>
          <tr>
            <th>形状</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>芽型</td>
            <td>针形或雀舌形</td>
            <td>匀齐</td>
            <td>净</td>
            <td>嫩黄</td>
            <td>清鲜</td>
            <td>鲜醇回甘</td>
            <td>杏黄明亮</td>
            <td>肥嫩黄亮</td>
          </tr>
          <tr>
            <td>芽叶型</td>
            <td>条形或扁形或兰花形</td>
            <td>较匀齐</td>
            <td>净</td>
            <td>黄青</td>
            <td>清高</td>
            <td>醇厚回甘</td>
            <td>黄明亮</td>
            <td>柔嫩黄亮</td>
          </tr>
          <tr>
            <td>多叶型</td>
            <td>卷略松</td>
            <td>尚匀</td>
            <td>有茎梗</td>
            <td>黄褐</td>
            <td>纯正、有锅巴香</td>
            <td>醇和</td>
            <td>深黄明亮</td>
            <td>尚软黄尚亮有茎梗</td>
          </tr>
          <tr>
            <td>紧压型</td>
            <td>规整</td>
            <td>紧实</td>
            <td>——</td>
            <td>褐黄</td>
            <td>醇正</td>
            <td>醇和</td>
            <td>深黄</td>
            <td>尚匀</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>水分/(g/100 g)≤</th>
            <th>总灰分/(g/100 g)≤</th>
            <th>碎茶和粉末(质量分数)/%≤</th>
            <th>水浸出物(质量分数)/%≥</th>
          </tr>
          <tr>
            <td rowspan="2">6.5</td>
            <td rowspan="2">7</td>
            <td>2</td>
            <td rowspan="4">32</td>
          </tr>
          <tr>
            <td>3</td>
          </tr>
          <tr>
            <td>7</td>
            <td rowspan="2">7.5</td>
            <td>6</td>
          </tr>
          <tr>
            <td>9</td>
            <td>——</td>
          </tr>
        </table>
      </div>

      <p>蒙顶黄芽</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/yellow/蒙顶黄芽-茶叶2.jpg" />
        <img src="../../assets/teaStandImg/yellow/蒙顶黄芽-制作.jpg" />
        <img src="../../assets/teaStandImg/yellow/蒙顶黄芽-汤色1.jpg" />
        <img src="../../assets/teaStandImg/yellow/蒙顶黄芽-冲泡1.jpg" />
      </div>
      <!-- 蒙顶黄芽 -->
      <div class="tablecontainer">
        <table>
          <tr>
            <th rowspan="3">蒙顶黄芽</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
          </tr>
          <tr>
            <th>条索</th>
            <th>色泽</th>
            <th>嫩度</th>
            <th>净度</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>扁平挺直</td>
            <td>嫩黄油润</td>
            <td>全芽坡毫</td>
            <td>净</td>
            <td>甜香馥郁</td>
            <td>甜香馥郁</td>
            <td>甜香馥郁</td>
            <td>黄亮鲜活</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>水分/%≤</th>
            <th>总灰分/%≤</th>
            <th>粉末/%≤</th>
            <th>含花量/% ≤</th>
          </tr>
          <tr>
            <td>6.5</td>
            <td>6.5</td>
            <td>0.5</td>
            <td>——</td>
          </tr>
        </table>
      </div>

      <p>沩山毛尖</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/yellow/沩山毛尖-茶叶2.jpg" />
        <img src="../../assets/teaStandImg/yellow/沩山毛尖-种植.jpg" />
        <img src="../../assets/teaStandImg/yellow/沩山毛尖-汤色.jpg" />
        <img src="../../assets/teaStandImg/yellow/沩山毛尖-采摘.jpg" />
      </div>
      <!-- 沩山毛尖 -->
      <div class="tablecontainer">
        <table>
          <tr>
            <th rowspan="2">沩山毛尖</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
          </tr>
          <tr>
            <th>形状</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>特级</td>
            <td>细紧、多亳</td>
            <td>匀整</td>
            <td>净</td>
            <td>黄润</td>
            <td>嫩香、高长</td>
            <td>醇甜爽口</td>
            <td>黄亮</td>
            <td>黄亮、嫩匀</td>
          </tr>
          <tr>
            <td>一级</td>
            <td>紧细、显亳</td>
            <td>较匀整</td>
            <td>净</td>
            <td>黄润</td>
            <td>清香、持久</td>
            <td>醇甜爽口</td>
            <td>黄亮</td>
            <td>黄亮、匀</td>
          </tr>
          <tr>
            <td>二级</td>
            <td>紧结、有毫</td>
            <td>尚匀整</td>
            <td>净</td>
            <td>尚黄润</td>
            <td>清香、纯正</td>
            <td>醇厚</td>
            <td>黄明</td>
            <td>黄、尚匀</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>水分/%≤</th>
            <th>总灰分/%≤</th>
            <th>粉末/%≤</th>
            <th>水浸出物(质量分数)/%≥</th>
            <th>碎末茶，%</th>
            <th>游离氨基酸，%</th>
            <th>粗纤维，%</th>
          </tr>
          <tr>
            <td rowspan="3">6.5</td>
            <td rowspan="3">6.5</td>
            <td>0.5</td>
            <td>38</td>
            <td>2</td>
            <td>3.5</td>
            <td>12</td>
          </tr>
          <tr>
            <td>0.7</td>
            <td>36</td>
            <td rowspan="2">3</td>
            <td>3</td>
            <td>13</td>
          </tr>
          <tr>
            <td>1</td>
            <td>34</td>
            <td>2.5</td>
            <td>14</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
p {
  text-align: left;
  font-size: 16px;
  margin: 20px;
  font-weight: bold;
  color: rgb(31, 83, 38);
}
table {
  border: 1px;
  border-collapse: collapse;
  width: 950px;
  text-align: center;
  margin-bottom: 20px;
}
.imgcontainer {
  display: flex;
  justify-content: space-around;
  width: 950px;
  margin-bottom: 20px;
}
img {
  width: 200px;
  height: 200px;
}

.imgcontainer {
  padding-left: 0;
  padding-right: 0;
}
.tablecontainer {
  margin-bottom: 80px;
}
table,
table tr th,
table tr td {
  border: 1px solid #050505;
}
</style>
