<template>
  <div style="overflow: scroll; height: 750px">
    <div>
      <!-- 米砖茶 -->
      <p>米砖茶</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/red/米砖1.jpg" />
        <img src="../../assets/teaStandImg/red/米砖2.jpg" />
      </div>
      <div class="tablecontainer">
        <table>
          <tr>
            <th>米砖茶</th>
            <th>级别</th>
            <th>外形</th>
            <th>内质</th>
            <th>水分（质量分数）％≤</th>
            <th>总灰分（质量分数）％≤</th>
            <th>非茶类夹杂物（质量分数）％≤</th>
            <th>水浸出物（质量分数）％≤</th>
          </tr>
          <tr>
            <td></td>
            <td>普通米砖茶</td>
            <td>
              砖面平整，棱角分明，厚薄一致，黑褐稍泛黄，图案清晰，砖内无黑霉、白霉、青霉、等霉菌。
            </td>
            <td>香气平正，滋味尚浓醇，汤色深红，叶底红暗。</td>
            <td>9.5(计重水分9.5%)</td>
            <td>7.5</td>
            <td>0.2</td>
            <td>30</td>
          </tr>
          <tr>
            <td></td>
            <td>特级米砖茶</td>
            <td>
              砖面平整，棱角分明，厚薄一致，乌黑油润，图案清晰，砖内无黑霉白霉、青霉、等菌。
            </td>
            <td>香气纯正，滋味浓醇，汤色深红，叶底红匀。</td>
            <td>9.5(计重水分9.5%)</td>
            <td>8</td>
            <td>0.2</td>
            <td>28</td>
          </tr>
        </table>
      </div>
      <!-- 茉莉红茶 -->
      <p>茉莉红茶</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/red/茉莉红茶1.jpg" />
        <img src="../../assets/teaStandImg/red/茉莉红茶2.jpg" />
        <img src="../../assets/teaStandImg/red/茉莉红茶3.jpg" />
      </div>
      <!-- 中小叶种茉莉红茶 -->
      <div class="tablecontainer">
        <table>
          <tr>
            <th>类别（中小叶茉莉红茶）</th>
            <th>等级</th>
            <th>外形</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>特种茉莉红茶</td>
            <td>/</td>
            <td>造型独特、洁净匀整、乌较润</td>
            <td>花香浓、甜香显</td>
            <td>鲜醇甘爽</td>
            <td>红明亮</td>
            <td>柔软、红匀明亮</td>
          </tr>
          <tr>
            <td>等级茉莉红茶</td>
            <td>特级</td>
            <td>细紧显亳多锋苗、匀净、乌黑油润</td>
            <td>花香浓郁、嫩甜香显</td>
            <td>鲜醇甘爽</td>
            <td>红明亮</td>
            <td>细嫩、红匀明亮</td>
          </tr>
          <tr>
            <td></td>
            <td>一级</td>
            <td>紧细、有锋苗带毫、匀净、乌较润</td>
            <td>花香较浓、显甜香</td>
            <td>醇厚较爽</td>
            <td>红亮</td>
            <td>嫩匀、红亮</td>
          </tr>
          <tr>
            <td></td>
            <td>二级</td>
            <td>紧结、较匀整、较乌尚润</td>
            <td>花香尚浓、有甜香</td>
            <td>醇和</td>
            <td>较红亮</td>
            <td>较软、红尚亮</td>
          </tr>
          <tr>
            <td></td>
            <td>三级</td>
            <td>较紧结、尚匀整、尚黑</td>
            <td>花香、甜香纯正</td>
            <td>醇正</td>
            <td>尚红</td>
            <td>尚软、较红</td>
          </tr>
        </table>
        <!-- 大叶种茉莉红茶 -->
        <table>
          <tr>
            <th>类别（大叶种茉莉红茶）</th>
            <th>等级</th>
            <th>外形</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>特种茉莉红茶</td>
            <td>/</td>
            <td>造型独特、洁净匀齐、乌褐油润、金毫显</td>
            <td>花香浓、甜香显</td>
            <td>鲜浓醇厚</td>
            <td>红亮</td>
            <td>柔软、红匀明亮</td>
          </tr>
          <tr>
            <td>等级茉莉红茶</td>
            <td>特级</td>
            <td>肥壮紧结多锋苗、匀净、乌褐油润、多金毫</td>
            <td>花香、甜香浓郁</td>
            <td>鲜浓醇爽</td>
            <td>红艳明亮</td>
            <td>肥嫩、红匀明亮</td>
          </tr>
          <tr>
            <td></td>
            <td>一级</td>
            <td>肥壮紧结有锋苗、匀净、乌褐润、有金毫</td>
            <td>花香、甜香较浓</td>
            <td>醇较浓</td>
            <td>红亮</td>
            <td>柔软、红匀亮</td>
          </tr>
          <tr>
            <td></td>
            <td>二级</td>
            <td>较肥壮紧实、较匀净、乌褐尚润</td>
            <td>花香、甜香尚浓</td>
            <td>较醇浓</td>
            <td>较红亮</td>
            <td>较柔软、红尚亮</td>
          </tr>
          <tr>
            <td></td>
            <td>三级</td>
            <td>较紧实、尚净、乌褐</td>
            <td>纯正尚浓</td>
            <td>尚醇</td>
            <td>红尚亮</td>
            <td>尚软、尚红</td>
          </tr>
        </table>
        <!-- 大叶种茉莉红茶 -->
        <table>
          <tr>
            <th>类别（大叶种茉莉红茶）</th>
            <th>水分/（g/100g）≤</th>
            <th>总灰分/（g/100g）≤</th>
            <th>碎茶（质量分数）/%≤</th>
            <th>粉末（质量分数）/%≤</th>
            <th>茉莉花干（质量分数）/%≤</th>
            <th>水浸出物（质量分数）/%≥</th>
          </tr>
          <tr>
            <td>特种茉莉红茶</td>
            <td>8.5</td>
            <td>6.5</td>
            <td>5</td>
            <td>1</td>
            <td>1</td>
            <td>32</td>
          </tr>
          <tr>
            <td>等级茉莉红茶</td>
            <td>8.5</td>
            <td>6.5</td>
            <td>7</td>
            <td>1.2</td>
            <td>1.5</td>
            <td>30</td>
          </tr>
        </table>
      </div>
      <!-- 祁门工夫 -->
      <p>祁门工夫</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/red/祁门工夫1.jpg" />
        <img src="../../assets/teaStandImg/red/祁门工夫2.jpg" />
      </div>
      <div class="tablecontainer">
        <table>
          <tr>
            <th rowspan="2">级别</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
            <th rowspan="2">水分g/100g≤</th>
            <th rowspan="2">总灰分g/100g（以干物质计）≤</th>
            <th rowspan="2">水浸出物（质量分数）/%≥</th>
            <th rowspan="2">粉末（质量分数）/%≤</th>
          </tr>
          <tr>
            <th>条索</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>礼茶</td>
            <td>细嫩挺秀 金毫显露</td>
            <td>匀齐</td>
            <td>洁净</td>
            <td>乌油润</td>
            <td>祁门香显 毫香显</td>
            <td>鲜醇甘爽</td>
            <td>红艳明亮</td>
            <td>细嫩多芽柔软，红匀明亮</td>
            <td>7</td>
            <td>6.5</td>
            <td>32</td>
            <td>1</td>
          </tr>
          <tr>
            <td>特茗</td>
            <td>细嫩露毫 金毫显露</td>
            <td>匀齐</td>
            <td>洁净</td>
            <td>乌油润</td>
            <td>祁门香显 甜香浓</td>
            <td>鲜醇甘爽</td>
            <td>红艳明亮</td>
            <td>细嫩显芽 柔软，红匀明亮</td>
            <td>7</td>
            <td>6.5</td>
            <td>32</td>
            <td>1</td>
          </tr>
          <tr>
            <td>特级</td>
            <td>细嫩挺秀 锋苗显</td>
            <td>匀整</td>
            <td>洁净</td>
            <td>乌润</td>
            <td>甜香、祁门香显</td>
            <td>甜醇爽口</td>
            <td>红艳明</td>
            <td>红艳匀亮 细嫩显芽</td>
            <td>7</td>
            <td>6.5</td>
            <td>32</td>
            <td>1</td>
          </tr>
          <tr>
            <td>一级</td>
            <td>细紧显锋苗</td>
            <td>较匀整、匀齐</td>
            <td>净</td>
            <td>乌较润</td>
            <td>有祁门香 甜香</td>
            <td>甜醇</td>
            <td>红艳</td>
            <td>柔嫩有芽 红艳</td>
            <td>7</td>
            <td>6.5</td>
            <td>32</td>
            <td>1</td>
          </tr>
          <tr>
            <td>二级</td>
            <td>紧细有锋苗</td>
            <td>较匀整</td>
            <td>尚净</td>
            <td>乌尚润</td>
            <td>甜香显</td>
            <td>醇厚</td>
            <td>红亮</td>
            <td>红亮 嫩匀</td>
            <td>7</td>
            <td>6.5</td>
            <td>32</td>
            <td>1</td>
          </tr>
          <tr>
            <td>三级</td>
            <td>尚紧细</td>
            <td>尚匀整</td>
            <td>尚净、稍有茎</td>
            <td>尚乌润</td>
            <td>有甜香</td>
            <td>醇和</td>
            <td>红尚亮</td>
            <td>尚嫩匀 红亮</td>
            <td>7</td>
            <td>6.5</td>
            <td>30</td>
            <td>1</td>
          </tr>
          <tr>
            <td>四级</td>
            <td>尚紧</td>
            <td>尚匀</td>
            <td>尚净、稍有茎梗</td>
            <td>乌</td>
            <td>纯正</td>
            <td>醇</td>
            <td>红明</td>
            <td>红尚匀</td>
            <td>7</td>
            <td>6.5</td>
            <td>30</td>
            <td>1</td>
          </tr>
        </table>
      </div>
      <!-- 坦洋工夫 -->
      <p>坦洋工夫</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/red/坦洋工夫1.jpg" />
        <img src="../../assets/teaStandImg/red/坦洋工夫2.jpg" />
        <img src="../../assets/teaStandImg/red/坦洋工夫3.jpg" />
      </div>
      <div class="tablecontainer">
        <table>
          <tr>
            <th rowspan="2">级别</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
            <th rowspan="2">水分g/100g≤</th>
            <th rowspan="2">总灰分g/100g（以干物质计）≤</th>
            <th rowspan="2">碎茶% ≤</th>
            <th rowspan="2">粉末（质量分数）/%≤</th>
            <th rowspan="2">水浸出物（质量分数）/%≥</th>
          </tr>
          <tr>
            <th>条索</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>特级</td>
            <td>肥嫩紧细、毫显、多锋苗</td>
            <td>匀整</td>
            <td>洁净</td>
            <td>乌黑油润</td>
            <td>甜香浓郁</td>
            <td>鲜浓醇</td>
            <td>红艳</td>
            <td>细嫩柔软，红亮</td>
            <td>7</td>
            <td>6.5</td>
            <td>3</td>
            <td>0.5</td>
            <td>32</td>
          </tr>
          <tr>
            <td>一级</td>
            <td>肥嫩紧细、有锋苗</td>
            <td>匀整</td>
            <td>较洁净</td>
            <td>乌润</td>
            <td>甜香</td>
            <td>鲜醇较浓</td>
            <td>较红艳</td>
            <td>柔软红亮</td>
            <td>7</td>
            <td>6.5</td>
            <td>3</td>
            <td>1</td>
            <td>32</td>
          </tr>
          <tr>
            <td>二级</td>
            <td>较肥壮紧实</td>
            <td>较匀整</td>
            <td>较净，稍有嫩茎</td>
            <td>较乌润</td>
            <td>香较高</td>
            <td>较醇厚</td>
            <td>红尚亮</td>
            <td>红尚亮</td>
            <td>7</td>
            <td>6.5</td>
            <td>3</td>
            <td>1</td>
            <td>32</td>
          </tr>
          <tr>
            <td>三级</td>
            <td>尚紧实</td>
            <td>尚匀整</td>
            <td>尚净，有筋梗</td>
            <td>乌尚润</td>
            <td>纯正</td>
            <td>醇和</td>
            <td>红</td>
            <td>红欠匀</td>
            <td>7</td>
            <td>6.5</td>
            <td>5</td>
            <td>1.5</td>
            <td>30</td>
          </tr>
          <tr>
            <td>紧压茶</td>
            <td colspan="4">
              方形、圆形或心形等；纹理清晰，平滑紧实，厚薄均匀、色泽乌润
            </td>
            <td colspan="4">参照上述各等级内质感官品质特征的指标要求</td>
          </tr>
        </table>
      </div>
      <!-- 正山小种 -->
      <p>正山小种</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/red/正山小种1.jpg" />
        <img src="../../assets/teaStandImg/red/正山小种2.jpg" />
        <img src="../../assets/teaStandImg/red/正山小种3.jpg" />
      </div>
      <div class="tablecontainer">
        <table>
          <tr>
            <th>品名</th>
            <th>茶水比</th>
            <th>水温（℃）</th>
            <th>第一泡（s）</th>
            <th>第二泡（s）</th>
            <th>第三泡（s）</th>
            <th>第四泡（s）</th>
          </tr>
          <tr>
            <td>正山小种红茶</td>
            <td>0.0625</td>
            <td>95～100</td>
            <td>8</td>
            <td>5</td>
            <td>12</td>
            <td>20</td>
          </tr>
          <tr>
            <td>陈年正山小种红茶</td>
            <td>0.0625</td>
            <td>95～100</td>
            <td>10</td>
            <td>5</td>
            <td>15</td>
            <td>25</td>
          </tr>
        </table>
        <table>
          <tr>
            <th rowspan="2">级别（正山小种红茶）</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
          </tr>
          <tr>
            <th>条索</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>特级</td>
            <td>壮实紧结</td>
            <td>乌黑油润</td>
            <td>匀齐</td>
            <td>净</td>
            <td>纯正高长，似桂圆干香或松烟香明显</td>
            <td>醇厚回甘，显高山韵，似桂圆汤味明显</td>
            <td>橙红明亮</td>
            <td>尚嫩较软有皱褶，古铜色匀齐</td>
          </tr>
          <tr>
            <td>一级</td>
            <td>尚壮实</td>
            <td>乌尚润</td>
            <td>较匀齐</td>
            <td>稍有茎梗</td>
            <td>纯正、有似桂圆干香</td>
            <td>厚尚醇回甘，尚显高山韵，似桂圆汤味尚明</td>
            <td>橙红尚亮</td>
            <td>有皱褶，古铜色稍暗，尚匀亮</td>
          </tr>
          <tr>
            <td>二级</td>
            <td>稍粗实</td>
            <td>欠乌润</td>
            <td>尚匀整</td>
            <td>有茎梗</td>
            <td>松烟香稍淡</td>
            <td>尚厚，略有似桂圆汤味</td>
            <td>橙红欠亮</td>
            <td>稍粗硬铜色稍暗</td>
          </tr>
          <tr>
            <td>三级</td>
            <td>稍松</td>
            <td>乌、显花杂</td>
            <td>欠匀</td>
            <td>带粗梗</td>
            <td>平正、略有松烟香</td>
            <td>略粗、似桂圆汤味欠明、平和</td>
            <td>暗红</td>
            <td>稍花杂</td>
          </tr>
        </table>
        <table>
          <tr>
            <th rowspan="2">级别（陈年正山小种）</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
          </tr>
          <tr>
            <th>条索</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>特级</td>
            <td>壮实紧结</td>
            <td>乌黑润</td>
            <td>匀齐</td>
            <td>净</td>
            <td>陈香显、似桂圆干香或松烟香明，持久</td>
            <td>陈醇顺滑、有似桂圆汤味，高山韵较显</td>
            <td>橙红清澈</td>
            <td>尚嫩微软有皱褶，乌褐，匀</td>
          </tr>
          <tr>
            <td>一级</td>
            <td>尚壮实</td>
            <td>乌尚润</td>
            <td>较匀齐</td>
            <td>稍有茎梗</td>
            <td>陈香显，有桂圆干香或松烟香</td>
            <td>陈醇，略有似桂圆汤味、顺滑</td>
            <td>橙红稍深</td>
            <td>乌褐，有皱褶，尚匀</td>
          </tr>
          <tr>
            <td>二级</td>
            <td>稍粗实</td>
            <td>欠乌润</td>
            <td>尚匀整</td>
            <td>有茎梗</td>
            <td>陈香较显，带松烟香</td>
            <td>陈醇尚厚，较顺滑</td>
            <td>橙红稍暗</td>
            <td>乌褐稍暗，稍粗硬</td>
          </tr>
          <tr>
            <td>三级</td>
            <td>粗松</td>
            <td>乌褐，稍花杂</td>
            <td>欠匀</td>
            <td>带粗梗</td>
            <td>陈香尚显，平正，略有松烟香</td>
            <td>尚陈醇，稍粗</td>
            <td>橙红稍暗</td>
            <td>乌褐欠匀，稍暗，稍粗S硬</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
img {
  width: 200px;
  height: 200px;
  margin-left: 20px;
  margin-right: 20px;
}
.imgcontainer {
  display: flex;
  justify-content: center;
  width: 950px;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
}
.tablecontainer {
  margin-bottom: 80px;
}

p {
  text-align: left;
  font-size: 16px;
  margin: 20px;
  font-weight: bold;
  color: rgb(31, 83, 38);
}
table {
  border: 1px;

  border-collapse: collapse;
  width: 950px;
  text-align: center;
  margin-bottom: 20px;
}
table,
table tr th,
table tr td {
  border: 1px solid #050505;
}
</style>
