<template>
  <!-- 页面主体内容 -->
  <div class="item_content">
    <!-- 图片 -->
    <!-- <div class="item_img">
      <img src="../assets/tea_item.png" alt="" />
    </div> -->

    <!-- 主体内容 -->
    <div class="item_menu">
        <el-tabs v-model="activeTab" :tab-position="tabPosition" >
          <el-tab-pane label="六大品类" name="sixkind">
              <div class="sixkind" style="height:100%">
                <six-kind></six-kind>
              </div>
          </el-tab-pane>

          <el-tab-pane label="绿茶" name="greenTea" >
            <div class="greenTea">
              <green-tea></green-tea>
            </div>
          </el-tab-pane>
          <el-tab-pane label="黄茶" name="yellowTea" >
            <div class="yellowTea">
              <yellow-tea></yellow-tea>
            </div>
          </el-tab-pane>
          <el-tab-pane label="黑茶" name="blackTea" >
            <div class="blackTea">
              <black-tea></black-tea>
            </div>
          </el-tab-pane>
          <el-tab-pane label="白茶" name="whiteTea" >
            <div class="whiteTea">
              <white-tea></white-tea>
            </div>
          </el-tab-pane>
          <el-tab-pane label="乌龙茶" name="oolangTea" >
            <div class="oolangTea">
              <oolang-tea></oolang-tea>
            </div>
          </el-tab-pane>
          <el-tab-pane label="红茶" name="redTea" >
            <div class="redTea">
              <red-tea></red-tea>
            </div>
          </el-tab-pane>
        </el-tabs>
    </div>
  </div>
</template>

<script>
import sixKind from '@/views/teaCategory/sixKind';
import greenTea from '@/views/teaCategory/greenTea';
import yellowTea from '@/views/teaCategory/yellowTea';
import blackTea from '@/views/teaCategory/blackTea';
import whiteTea from '@/views/teaCategory/whiteTea';
import oolangTea from '@/views/teaCategory/oolangTea';
import redTea from '@/views/teaCategory/redTea';


export default {
  name: "tea",
  data() {
    return {
      tabPosition: "left",
      activeTab: String,
      
    };
  },
  methods: {},
  mounteds() {
    
  },
  components: {
    sixKind,
    greenTea,
    yellowTea,
    blackTea,
    whiteTea,
    oolangTea,
    redTea
  },
  created() {
    this.activeTab = this.$route.query.id || "sixkind";
  },
};
</script>
<style >
.item_content {
  width: 100%;
  padding-top: 10px;
  /* 底部距离保持 露出最下方 */
  margin-bottom: 50px;
  position: relative;
}
.el-tabs .el-tabs--left{
  height: 100%;
  overflow: scroll !important;
}
.el-main{
  padding: 0;
}
.item_menu {
  float: center;
  width: 1200px;
  padding-top: 80px;
  margin: 0 auto;
}
.sixKind .greenTea .yellowTea .blackTea .whiteTea .oolangTea .redTea{
  float: center;
  width: 950px;
  padding: 20px 0 0 30px;
  margin-left: 50px;
}
.el-tab-pane{
  margin: 20px 0 0 30px;
}
.sixKind{
  height: 1200px;
}
.el-tabs__content {
  /* height: 1200px; */
  /* overflow: scroll !important;
  position: relative; */
}
.el-tabs__item.is-active{
  color: rgb(14, 212, 14);
}
.el-tabs__active-bar{
  background-color:  rgb(14, 212, 14);
}
.el-tabs__item:hover {
    color: #07a10e; 
  }
.el-tabs el-tabs--left{
  overflow: auto;

}
</style>
