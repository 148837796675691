<template>
  <div style="overflow: scroll; height: 750px">
    <div>
      <p>贡眉</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/white/贡眉.jpg" />
        <img src="../../assets/teaStandImg/white/贡眉2.jpg" />
      </div>
      <div class="tablecontainer">
        <table>
          <tr>
            <th rowspan="2">贡眉</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
          </tr>
          <tr>
            <th>条索</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>特级</td>
            <td>叶态卷、有豪心</td>
            <td>匀整</td>
            <td>洁净</td>
            <td>灰绿或墨绿</td>
            <td>鲜嫩，有豪气</td>
            <td>清甜醇爽</td>
            <td>橙黄</td>
            <td>有尖牙、叶张嫩亮</td>
          </tr>
          <tr>
            <td>一级</td>
            <td>叶态尚卷、豪尖尚显</td>
            <td>较匀</td>
            <td>较洁净</td>
            <td>尚灰绿</td>
            <td>鲜嫩，有嫩香</td>
            <td>醇厚尚爽</td>
            <td>尚橙黄</td>
            <td>稍有芽尖、叶张软尚亮</td>
          </tr>
          <tr>
            <td>二级</td>
            <td>叶态略卷稍展、有破张</td>
            <td>尚匀</td>
            <td>夹黄片铁板片少量腊片</td>
            <td>灰绿稍暗、夹红</td>
            <td>浓纯</td>
            <td>浓厚</td>
            <td>深黄</td>
            <td>叶张较粗、稍摊、有张红</td>
          </tr>
          <tr>
            <td>三级</td>
            <td>叶张平展、破张多</td>
            <td>欠匀</td>
            <td>含鱼叶蜡片较多</td>
            <td>灰黄夹红稍葳</td>
            <td>浓、稍粗</td>
            <td>厚、稍粗</td>
            <td>深黄微红</td>
            <td>叶张粗杂、红张多</td>
          </tr>
        </table>
        <table>
          <tr>
            <th colspan="4">理化指标</th>
          </tr>
          <tr>
            <th>水分（质量分数）/% ≤</th>
            <th>总灰分（质量分数）/% ≤</th>
            <th>粉末（质量分数）/% ≤</th>
            <th>水浸出物（质量分数）/% ≤</th>
          </tr>
          <tr>
            <td>8.5</td>
            <td>6.5</td>
            <td>1.0</td>
            <td>30</td>
          </tr>
        </table>
      </div>
    </div>
    <div>
      <p>政和白毫银针</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/white/政和白毫银针1.jpg" />
        <img src="../../assets/teaStandImg/white/政和白毫银针2.jpg" />
      </div>
      <div class="tablecontainer">
        <table>
          <tr>
            <th rowspan="2">政和白毫银针</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
          </tr>
          <tr>
            <th>条索</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>特级</td>
            <td>芽针肥壮、茸毛厚</td>
            <td>匀齐</td>
            <td>洁净</td>
            <td>银灰白富有光泽</td>
            <td>清纯、豪香显露</td>
            <td>清鲜醇爽、豪味足</td>
            <td>浅杏黄、清澈明亮</td>
            <td>肥壮、柔软、明亮</td>
          </tr>
          <tr>
            <td>一级</td>
            <td>芽针秀长、茸毛略薄</td>
            <td>较匀齐</td>
            <td>洁净</td>
            <td>银灰白</td>
            <td>清纯、豪香显</td>
            <td>鲜醇爽、豪味显</td>
            <td>杏黄、清澈明亮</td>
            <td>嫩匀明亮</td>
          </tr>
        </table>
        <table>
          <tr>
            <th colspan="3">理化指标</th>
          </tr>
          <tr>
            <th>水分（质量分数）/% ≤</th>
            <th>总灰分（质量分数）/% ≤</th>
            <th>水浸出物（质量分数）/% ≤</th>
          </tr>
          <tr>
            <td>8.5</td>
            <td>6.5</td>
            <td>30</td>
          </tr>
        </table>
        <table>
          <tr>
            <th colspan="13">质量安全指标</th>
          </tr>
          <tr>
            <th>铅(以Pb计) (mg/kg) ≤</th>
            <th>氰戊菊酯 (mg/kg) ≤</th>
            <th>联苯菊酯 (mg/kg) ≤</th>
            <th>氯氰菊酯 (mg/kg) ≤</th>
            <th>溴氰菊酯 (mg/kg) ≤</th>
            <th>三氯杀螨醇 (mg/kg) ≤</th>
            <th>乐果 (mg/kg) ≤</th>
            <th>敌敌畏 (mg/kg) ≤</th>
            <th>滴滴涕 (mg/kg) ≤</th>
            <th>杀螟硫磷 (mg/kg) ≤</th>
            <th>喹硫磷 (mg/kg) ≤</th>
            <th>乙酰甲胺磷 (mg/kg） ≤</th>
            <th>稀土总量 (mg/kg) ≤</th>
          </tr>
          <tr>
            <td>5.0</td>
            <td>0.1</td>
            <td>5.0</td>
            <td>0.5</td>
            <td>5.0</td>
            <td>0.1</td>
            <td>1.0</td>
            <td>0.1</td>
            <td>0.2</td>
            <td>0.5</td>
            <td>0.2</td>
            <td>0.1</td>
            <td>2.0</td>
          </tr>
        </table>
      </div>
    </div>
    <div>
      <p>水吉白牡丹</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/white/水吉白牡丹.jpg" />
        <img src="../../assets/teaStandImg/white/水吉白牡丹2.jpg" />
      </div>
      <div class="tablecontainer">
        <table>
          <tr>
            <th rowspan="2">水吉白牡丹</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
          </tr>
          <tr>
            <th>条索</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>特级</td>
            <td>豪心多肥壮、叶背多茸毛</td>
            <td>匀整</td>
            <td>洁净</td>
            <td>灰绿润</td>
            <td>鲜嫩、纯爽豪香显</td>
            <td>清甜醇爽豪味足</td>
            <td>黄、清澈</td>
            <td>芽心多，叶张肥嫩明亮</td>
          </tr>
          <tr>
            <td>一级</td>
            <td>豪心较显、尚壮、叶张嫩</td>
            <td>尚匀整</td>
            <td>较洁净</td>
            <td>灰绿尚润</td>
            <td>尚鲜嫩、纯爽有豪香</td>
            <td>较清甜、醇爽</td>
            <td>尚黄、清澈</td>
            <td>芽心较多、叶张嫩，尚明</td>
          </tr>
          <tr>
            <td>二级</td>
            <td>豪心尚显、叶张尚嫩</td>
            <td>尚匀</td>
            <td>含少量黄绿片</td>
            <td>尚灰绿</td>
            <td>浓纯、略有豪香</td>
            <td>尚清甜、醇厚</td>
            <td>橙黄</td>
            <td>有芽心、叶张尚嫩、稍有张红</td>
          </tr>
          <tr>
            <td>三级</td>
            <td>叶缘略卷、有平展叶、破张叶</td>
            <td>欠匀</td>
            <td>稍夹黄片腊片</td>
            <td>灰绿稍暗</td>
            <td>尚浓纯</td>
            <td>尚厚</td>
            <td>尚橙黄</td>
            <td>叶张尚软有破张、红张稍多</td>
          </tr>
        </table>
        <table>
          <tr>
            <th colspan="4">理化指标</th>
          </tr>
          <tr>
            <th>水分（质量分数）/% ≤</th>
            <th>总灰分（质量分数）/% ≤</th>
            <th>粉末（质量分数）/% ≤</th>
            <th>水浸出物（质量分数）/% ≤</th>
          </tr>
          <tr>
            <td>8.5</td>
            <td>6.5</td>
            <td>1.0</td>
            <td>30</td>
          </tr>
        </table>
        <table>
          <tr>
            <th colspan="13">质量安全指标</th>
          </tr>
          <tr>
            <th>铅(以Pb计) (mg/kg) ≤</th>
            <th>氰戊菊酯 (mg/kg) ≤</th>
            <th>联苯菊酯 (mg/kg) ≤</th>
            <th>氯氰菊酯 (mg/kg) ≤</th>
            <th>溴氰菊酯 (mg/kg) ≤</th>
            <th>三氯杀螨醇 (mg/kg) ≤</th>
            <th>乐果 (mg/kg) ≤</th>
            <th>敌敌畏 (mg/kg) ≤</th>
            <th>滴滴涕 (mg/kg) ≤</th>
            <th>杀螟硫磷 (mg/kg) ≤</th>
            <th>喹硫磷 (mg/kg) ≤</th>
            <th>乙酰甲胺磷 (mg/kg） ≤</th>
            <th>稀土总量 (mg/kg) ≤</th>
          </tr>
          <tr>
            <td>5.0</td>
            <td>0.1</td>
            <td>5.0</td>
            <td>0.5</td>
            <td>5.0</td>
            <td>0.1</td>
            <td>1.0</td>
            <td>0.1</td>
            <td>0.2</td>
            <td>0.5</td>
            <td>0.2</td>
            <td>0.1</td>
            <td>2.0</td>
          </tr>
        </table>
      </div>
    </div>
    <div>
      <p>政和白牡丹</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/white/政和白牡丹1.jpg" />
        <img src="../../assets/teaStandImg/white/政和白牡丹2.jpg" />
      </div>
      <div class="tablecontainer">
        <table>
          <tr>
            <th rowspan="2">政和白牡丹</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
          </tr>
          <tr>
            <th>条索</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>特级</td>
            <td>豪心多肥壮、叶背多茸毛</td>
            <td>匀整</td>
            <td>洁净</td>
            <td>灰绿润</td>
            <td>鲜嫩、纯爽豪香显</td>
            <td>清甜醇爽豪味足</td>
            <td>黄、清澈</td>
            <td>芽心多，叶张肥嫩明亮</td>
          </tr>
          <tr>
            <td>一级</td>
            <td>豪心较显、尚壮、叶张嫩</td>
            <td>尚匀整</td>
            <td>较洁净</td>
            <td>灰绿尚润</td>
            <td>尚鲜嫩、纯爽有豪香</td>
            <td>较清甜、醇爽</td>
            <td>尚黄、清澈</td>
            <td>芽心较多、叶张嫩，尚明</td>
          </tr>
          <tr>
            <td>二级</td>
            <td>豪心尚显、叶张尚嫩</td>
            <td>尚匀</td>
            <td>含少量黄绿片</td>
            <td>尚灰绿</td>
            <td>浓纯、略有豪香</td>
            <td>尚清甜、醇厚</td>
            <td>橙黄</td>
            <td>有芽心、叶张尚嫩、稍有张红</td>
          </tr>
          <tr>
            <td>三级</td>
            <td>叶缘略卷、有平展叶、破张叶</td>
            <td>欠匀</td>
            <td>稍夹黄片腊片</td>
            <td>灰绿稍暗</td>
            <td>尚浓纯</td>
            <td>尚厚</td>
            <td>尚橙黄</td>
            <td>叶张尚软有破张、红张稍多</td>
          </tr>
        </table>
        <table>
          <tr>
            <th colspan="4">理化指标</th>
          </tr>
          <tr>
            <th>水分（质量分数）/% ≤</th>
            <th>总灰分（质量分数）/% ≤</th>
            <th>粉末（质量分数）/% ≤</th>
            <th>水浸出物（质量分数）/% ≤</th>
          </tr>
          <tr>
            <td>8.5</td>
            <td>6.5</td>
            <td>1.0</td>
            <td>30</td>
          </tr>
        </table>
        <table>
          <tr>
            <th colspan="13">质量安全指标</th>
          </tr>
          <tr>
            <th>铅(以Pb计) (mg/kg) ≤</th>
            <th>氰戊菊酯 (mg/kg) ≤</th>
            <th>联苯菊酯 (mg/kg) ≤</th>
            <th>氯氰菊酯 (mg/kg) ≤</th>
            <th>溴氰菊酯 (mg/kg) ≤</th>
            <th>三氯杀螨醇 (mg/kg) ≤</th>
            <th>乐果 (mg/kg) ≤</th>
            <th>敌敌畏 (mg/kg) ≤</th>
            <th>滴滴涕 (mg/kg) ≤</th>
            <th>杀螟硫磷 (mg/kg) ≤</th>
            <th>喹硫磷 (mg/kg) ≤</th>
            <th>乙酰甲胺磷 (mg/kg） ≤</th>
            <th>稀土总量 (mg/kg) ≤</th>
          </tr>
          <tr>
            <td>5.0</td>
            <td>0.1</td>
            <td>5.0</td>
            <td>0.5</td>
            <td>5.0</td>
            <td>0.1</td>
            <td>1.0</td>
            <td>0.1</td>
            <td>0.2</td>
            <td>0.5</td>
            <td>0.2</td>
            <td>0.1</td>
            <td>2.0</td>
          </tr>
        </table>
      </div>
    </div>
    <div>
      <p>寿眉</p>
      <div class="imgcontainer">
        <img src="../../assets/teaStandImg/white/寿眉1.jpg" />
        <img src="../../assets/teaStandImg/white/寿眉2.jpg" />
      </div>
      <div class="tablecontainer">
        <table>
          <tr>
            <th rowspan="2">寿眉</th>
            <th colspan="4">外形</th>
            <th colspan="4">内质</th>
          </tr>
          <tr>
            <th>条索</th>
            <th>整碎</th>
            <th>净度</th>
            <th>色泽</th>
            <th>香气</th>
            <th>滋味</th>
            <th>汤色</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>一级</td>
            <td>叶态尚紧卷</td>
            <td>较匀</td>
            <td>较洁净</td>
            <td>尚灰绿</td>
            <td>纯</td>
            <td>醇厚尚爽</td>
            <td>尚橙黄</td>
            <td>稍有芽尖、叶张软尚亮</td>
          </tr>
          <tr>
            <td>二级</td>
            <td>叶态略卷稍展、有涨破</td>
            <td>尚匀</td>
            <td>夹黄片铁板片少量腊片</td>
            <td>灰绿稍暗、夹红</td>
            <td>浓纯</td>
            <td>浓厚</td>
            <td>深黄</td>
            <td>叶张较粗、稍摊、有张红</td>
          </tr>
        </table>
        <table>
          <tr>
            <th colspan="4">理化指标</th>
          </tr>
          <tr>
            <th>水分（质量分数）/% ≤</th>
            <th>总灰分（质量分数）/% ≤</th>
            <th>粉末（质量分数）/% ≤</th>
            <th>水浸出物（质量分数）/% ≤</th>
          </tr>
          <tr>
            <td>8.5</td>
            <td>6.5</td>
            <td>1.0</td>
            <td>30</td>
          </tr>
        </table>
      </div>
      <div>
        <p>寿眉/DB</p>
        <table>
          <tr>
            <th rowspan="3">寿眉/DB</th>
            <th colspan="2">鲜叶原料要求及分级</th>
            <th colspan="7">感官指标</th>
          </tr>
          <tr>
            <th rowspan="2">春季</th>
            <th rowspan="2">夏、秋季</th>
            <th colspan="3">外形</th>
            <th colspan="4">内质</th>
          </tr>

          <tr>
            <th>形状</th>
            <th>色泽</th>
            <th>匀净度</th>
            <th>香气</th>
            <th>汤色</th>
            <th>滋味</th>
            <th>叶底</th>
          </tr>
          <tr>
            <td>特级</td>
            <td>单芽达80％以上，余为一芽一叶初展；芽叶完整，长短一致</td>
            <td>--</td>
            <td>扁略弯</td>
            <td>翠绿显白毫</td>
            <td>匀净</td>
            <td>嫩香</td>
            <td>嫩绿明亮</td>
            <td>鲜爽</td>
            <td>嫩匀、绿明亮</td>
          </tr>
          <tr>
            <td>一级</td>
            <td>
              一芽一叶初展达60％～80％，余为一芽一叶； 芽叶完整，长短基本一致
            </td>
            <td>单芽达80％以上，余为一芽一中展； 芽叶完整，长短一致</td>
            <td>扁略弯</td>
            <td>绿尚润显白毫</td>
            <td>匀净</td>
            <td>清香</td>
            <td>绿、明亮</td>
            <td>鲜醇</td>
            <td>绿、明亮</td>
          </tr>
          <tr>
            <td>二级</td>
            <td>一芽一叶为主，一芽二叶初展不超过30%；芽叶完整，长短基本一致</td>
            <td>
              一芽一叶初展达60％～80％，余为一芽一叶；芽叶完整，长短基本一致
            </td>
            <td>扁显弯</td>
            <td>尚绿隐毫</td>
            <td>匀净</td>
            <td>尚清香</td>
            <td>绿亮</td>
            <td>醇和</td>
            <td>绿、尚亮</td>
          </tr>
        </table>
        <table>
          <tr>
            <th colspan="5">理化指标</th>
          </tr>
          <tr>
            <th>水分/% ≤</th>
            <th>总灰分/% ≤</th>
            <th>水浸出物/% ≤</th>
            <th>碎 末 /% ≤</th>
          </tr>
          <tr>
            <td>6.5</td>
            <td>6.5</td>
            <td>36</td>
            <td>5.0</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.imgcontainer {
  display: flex;
  justify-content: center;
  width: 950px;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
}
img {
  width: 200px;
  height: 200px;
  margin-left: 20px;
  margin-right: 20px;
}

.imgcontainer {
  padding-left: 0;
  padding-right: 0;
}
.tablecontainer {
  margin-bottom: 80px;
}
p {
  text-align: left;
  font-size: 16px;
  margin: 20px;
  font-weight: bold;
  color: rgb(31, 83, 38);
}
table {
  border: 1px;

  border-collapse: collapse;
  width: 950px;
  text-align: center;
  margin-bottom: 20px;
}
table,
table tr th,
table tr td {
  border: 1px solid #050505;
}
</style>
