<template>
  <div>
    <div style="overflow: scroll; height: 750px">
      <p>珠茶</p>
      <table>
        <tr>
          <th rowspan="2">珠茶</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>颗粒</th>
          <th>整碎</th>
          <th>色泽</th>
          <th>净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶度</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>圆结 重实</td>
          <td>匀整</td>
          <td>乌绿润 起霜</td>
          <td>洁净</td>
          <td>浓醇</td>
          <td>浓厚</td>
          <td>黄绿 明亮</td>
          <td>嫩匀 嫩绿 明亮</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>尚圆结尚实</td>
          <td>尚匀整</td>
          <td>乌绿尚润</td>
          <td>尚洁净</td>
          <td>浓纯</td>
          <td>醇厚</td>
          <td>黄绿 尚明亮</td>
          <td>嫩尚 匀黄 绿亮</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>圆整</td>
          <td>匀称</td>
          <td>尚乌绿润</td>
          <td>稍有黄头</td>
          <td>纯正</td>
          <td>醇和</td>
          <td>黄绿 尚明</td>
          <td>尚嫩 匀黄 绿明</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>尚圆整</td>
          <td>尚匀称</td>
          <td>乌绿带黄</td>
          <td>露黄头 有嫩茎</td>
          <td>尚纯正</td>
          <td>尚醇和</td>
          <td>黄绿</td>
          <td>黄绿尚匀</td>
        </tr>
        <tr>
          <td>四级</td>
          <td>粗圆</td>
          <td>欠匀</td>
          <td>黄乌尚匀</td>
          <td>稍有黄扁块 有茎梗</td>
          <td>平和</td>
          <td>稍带粗味</td>
          <td>黄尚明</td>
          <td>黄尚匀</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>总灰分/%（质量分数）≤</th>
          <th>粉末/%（质量分数）≤</th>
          <th>水浸出物/%（质量分数）≤</th>
          <th>粗纤维/%（质量分数）≤</th>
          <th>酸不溶性灰分/%（质量分数）≤</th>
          <th>水溶性灰分，占总灰分/%（质量分数）≥</th>
          <th>水溶性灰分碱度（以KOH计）/%（质量分数）</th>
          <th>茶多酚/%（质量分数）≥</th>
          <th>儿茶素/%（质量分数）≥</th>
        </tr>
        <tr>
          <td rowspan="2">7.0</td>
          <td rowspan="2">7.5</td>
          <td>1.0</td>
          <td>35.0</td>
          <td>15.0</td>
          <td rowspan="2">1.0</td>
          <td rowspan="2">45</td>
          <td rowspan="2">≥1.0a;≤3.0a</td>
          <td>14</td>
          <td>9</td>
        </tr>
        <tr>
          <td>1.5</td>
          <td>33.0</td>
          <td>16.5</td>
          <td>12</td>
          <td>8</td>
        </tr>
        <tr>
          <td colspan="10">
            注：粗纤维、酸不溶性成分、水溶性灰分、水溶性碱度为参考指标。
          </td>
        </tr>
        <tr>
          <td colspan="10">
            当以每100g磨碎样品的毫克当量表示水溶灰分碱度时，其限量为：最小值17.8；最大值53.6。
          </td>
        </tr>
      </table>

      <!-- 11-69 -->
      <p>涌溪火青</p>
      <!-- 涌溪火青 -->

      <table>
        <tr>
          <th rowspan="2">涌溪火青</th>
          <th rowspan="2">采摘标准</th>
          <th colspan="3">外形</th>
          <th colspan="4">内质</th>
          <th>水分/% ≤</th>
          <th>6.0</th>
        </tr>
        <tr>
          <th>形状</th>
          <th>色泽</th>
          <th>匀净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
          <th>粉末/% ≤</th>
          <th>0.5</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>一芽二叶<br />初展>80%<br />，一芽二叶&lt;20%<br /></td>
          <td>腰圆紧结重实</td>
          <td>绿润银毫</td>
          <td>匀整</td>
          <td>清花香 <br />高爽持久</td>
          <td>嫩绿 <br />鲜亮</td>
          <td>鲜醇爽 <br />回甘耐冲泡</td>
          <td>嫩绿肥厚<br />匀整成朵</td>
          <td>总灰分/% ≤</td>
          <td>6.5</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>一芽二叶<br />初展>50%<br />，一芽二叶&lt;50%</td>
          <td>腰圆紧结重实</td>
          <td>绿润银毫</td>
          <td>匀整</td>
          <td>清香 <br />高爽持久</td>
          <td>浅绿<br />明亮</td>
          <td>鲜厚耐泡<br />回味甘爽</td>
          <td>嫩黄绿<br />肥厚成朵</td>
          <td>水浸出物/% ≥</td>
          <td>37.0</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>一芽二叶为主</td>
          <td>较圆紧结重实</td>
          <td>绿润</td>
          <td>较匀整</td>
          <td>栗香</td>
          <td>黄绿<br />较明</td>
          <td>纯正鲜<br />浓耐泡</td>
          <td>嫩黄绿<br />肥壮尚匀</td>
          <td>铅（以Pb计）/（mg/kg） ≤</td>
          <td>2.0</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>一芽二叶</td>
          <td>较圆较紧</td>
          <td>墨绿</td>
          <td>尚匀整</td>
          <td>有香气</td>
          <td>黄绿</td>
          <td>纯正较浓</td>
          <td>黄绿</td>
          <td>稀土/（mg/kg） ≤</td>
          <td>2.0</td>
        </tr>
      </table>
      <!-- 珍眉 -->
      <p>珍眉</p>
      <table>
        <tr>
          <th rowspan="2">珍眉</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>整碎</th>
          <th>色泽</th>
          <th>净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特珍特级</td>
          <td>细紧<br />显锋苗</td>
          <td>匀整</td>
          <td>绿润<br />起霜</td>
          <td>洁净</td>
          <td>高香持久</td>
          <td>鲜浓醇厚</td>
          <td>绿明亮</td>
          <td>
            含芽<br />
            嫩绿明亮
          </td>
        </tr>
        <tr>
          <td>特珍一级</td>
          <td>
            细紧<br />
            有锋苗
          </td>
          <td>匀整</td>
          <td>
            绿润<br />
            起霜
          </td>
          <td>净</td>
          <td>高香</td>
          <td>鲜浓醇</td>
          <td>绿明亮</td>
          <td>
            嫩匀<br />
            嫩绿明亮
          </td>
        </tr>
        <tr>
          <td>特珍二级</td>
          <td>紧结</td>
          <td>尚匀整</td>
          <td>绿润</td>
          <td>尚净</td>
          <td>较高</td>
          <td>浓厚</td>
          <td>黄绿明亮</td>
          <td>
            嫩匀<br />
            绿明亮
          </td>
        </tr>
        <tr>
          <td>珍眉一级</td>
          <td>紧实</td>
          <td>尚匀整</td>
          <td>绿尚润</td>
          <td>尚净</td>
          <td>尚高</td>
          <td>浓醇</td>
          <td>黄绿尚明亮</td>
          <td>
            尚嫩匀<br />
            黄绿明亮
          </td>
        </tr>
        <tr>
          <td>珍眉二级</td>
          <td>尚紧实</td>
          <td>尚匀</td>
          <td>黄绿尚润</td>
          <td>稍有嫩茎</td>
          <td>纯正</td>
          <td>醇和</td>
          <td>黄绿</td>
          <td>
            尚匀软<br />
            黄绿
          </td>
        </tr>
        <tr>
          <td>珍眉三级</td>
          <td>粗实</td>
          <td>尚匀</td>
          <td>绿黄</td>
          <td>带细梗</td>
          <td>平正</td>
          <td>平和</td>
          <td>绿黄</td>
          <td>尚软绿黄</td>
        </tr>
        <tr>
          <td>珍眉四级</td>
          <td>稍粗松</td>
          <td>欠匀</td>
          <td>黄</td>
          <td>带梗朴</td>
          <td>稍粗</td>
          <td>稍粗淡</td>
          <td>黄稍暗</td>
          <td>稍粗绿黄</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>总灰分/%（质量分数）≤</th>
          <th>粉末/%（质量分数）≤</th>
          <th>水浸出物/%（质量分数）≤</th>
          <th>粗纤维/%（质量分数）≤</th>
          <th>酸不溶性灰分/%（质量分数）≤</th>
          <th>水溶性灰分，占总灰分/%（质量分数）≥</th>
          <th>水溶性灰分碱度（以KOH计）/%（质量分数）</th>
          <th>茶多酚/%（质量分数）≥</th>
          <th>儿茶素/%（质量分数）≥</th>
        </tr>
        <tr>
          <td>7.0</td>
          <td>7.5</td>
          <td>1.0</td>
          <td>36.0</td>
          <td>16.5</td>
          <td>1.0</td>
          <td>45</td>
          <td>≥1.0a;≤3.1a</td>
          <td>14</td>
          <td>9</td>
        </tr>
        <tr>
          <td colspan="10">
            注:粗纤维、酸不溶性成分、水溶性灰分、水溶性碱度为参考指标。
          </td>
        </tr>
        <tr>
          <td colspan="10">
            当以每100g磨碎样品的毫克当量表示水溶灰分碱度时，其限量为:最小值17.8；最大值53.6。
          </td>
        </tr>
      </table>
      <!-- 碧螺春 -->
      <p>碧螺春</p>
      <table>
        <tr>
          <th rowspan="2">碧螺春</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
          <th>水分/%（质量分数）≤</th>
          <th>7.5</th>
        </tr>
        <tr></tr>
        <tr>
          <th>条索</th>
          <th>整碎</th>
          <th>色泽</th>
          <th>净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
          <th>总灰分a/% ≤</th>
          <th>6.5</th>
        </tr>
        <tr>
          <td>特级一等</td>
          <td>纤细 卷曲呈螺 满身披毫</td>
          <td>
            银绿隐翠<br />
            鲜润
          </td>
          <td>匀整</td>
          <td>洁净</td>
          <td>嫩香清鲜</td>
          <td>清鲜甘醇</td>
          <td>嫩绿鲜亮</td>
          <td>
            幼嫩多芽<br />
            嫩绿鲜活
          </td>
          <td>水浸出物/% ≥</td>
          <td>34.0</td>
        </tr>
        <tr>
          <td>特级二等</td>
          <td>纤细 卷曲呈螺 满身披毫</td>
          <td>
            银绿隐翠<br />
            较鲜润
          </td>
          <td>匀整</td>
          <td>洁净</td>
          <td>嫩香清鲜</td>
          <td>清鲜甘醇</td>
          <td>嫩绿鲜亮</td>
          <td>
            幼嫩多芽<br />
            嫩绿鲜活
          </td>
          <td>粗纤维/% ≤</td>
          <td>14.0</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>
            尚纤细 卷曲呈螺 <br />
            白毫披覆
          </td>
          <td>
            银绿<br />
            隐翠
          </td>
          <td>匀整</td>
          <td>匀净</td>
          <td>嫩爽清香</td>
          <td>鲜醇</td>
          <td>鲜醇</td>
          <td>嫩、绿明亮</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>二级</td>
          <td>
            紧细 卷曲呈螺 <br />
            白毫显露
          </td>
          <td>绿润</td>
          <td>匀尚整</td>
          <td>匀、尚净</td>
          <td>清香</td>
          <td>鲜醇</td>
          <td>绿尚明亮</td>
          <td>
            嫩、略含<br />
            单张、绿明亮
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>三级</td>
          <td>
            尚紧细 尚卷曲呈螺<br />
            尚显白毫
          </td>
          <td>尚绿润</td>
          <td>尚匀整</td>
          <td>尚净 有单张</td>
          <td>纯正</td>
          <td>醇厚</td>
          <td>绿尚明亮</td>
          <td>
            尚嫩、<br />
            含单张、<br />
            绿尚亮
          </td>
          <td></td>
          <td></td>
        </tr>
      </table>
      <!-- 都匀毛尖 -->
      <p>都匀毛尖</p>
      <table>
        <tr>
          <th rowspan="2">珍眉</th>
          <th rowspan="2">外形</th>
          <th colspan="4">内质</th>
          <th rowspan="2">水分/%（质量分数）≤</th>
          <th rowspan="2">总灰分/%（质量分数）≤</th>
          <th rowspan="2">粉末/%（质量分数）≤</th>
          <th rowspan="2">水浸出物/%（质量分数）≤</th>
          <th rowspan="2">粗纤维/%（质量分数）≤</th>
          <th rowspan="2">水溶性灰分，占总灰分/%（质量分数）≥</th>
          <th rowspan="2">铅（以Pb计）≤ 限量 mg/kg</th>
        </tr>
        <tr>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>尊品</td>
          <td>紧细弯曲、满披白毫、匀整、嫩绿、净</td>
          <td>嫩香、栗香</td>
          <td>鲜醇</td>
          <td>嫩黄绿明亮</td>
          <td>嫩绿、鲜活匀整</td>
          <td>6.0</td>
          <td>6.5</td>
          <td>1.0</td>
          <td>43.2</td>
          <td>15.0</td>
          <td>50.0</td>
          <td>0.6</td>
        </tr>
        <tr>
          <td>珍品</td>
          <td>紧细较卷、白毫显露、匀整、绿润、净</td>
          <td>
            嫩香、栗香<br />
            清香
          </td>
          <td>
            鲜爽 <br />
            回甘
          </td>
          <td>嫩（浅）黄绿明亮</td>
          <td>
            嫩匀、鲜活 黄绿<br />
            明亮
          </td>
          <td>6.5</td>
          <td>6.5</td>
          <td>1.0</td>
          <td>40.0</td>
          <td>15.0</td>
          <td>50.0</td>
          <td>4.0</td>
        </tr>
        <tr>
          <td>特级</td>
          <td>较紧细、弯曲露毫、匀整、绿润、净</td>
          <td>清香、栗香</td>
          <td>醇厚</td>
          <td>黄绿较亮</td>
          <td>黄绿较亮</td>
          <td>6.5</td>
          <td>6.5</td>
          <td>1.0</td>
          <td>40.0</td>
          <td>15.0</td>
          <td>50.0</td>
          <td>4.0</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>紧结、较弯曲、匀整、深绿、尚净</td>
          <td>纯正</td>
          <td>醇和</td>
          <td>黄绿尚亮</td>
          <td>黄绿较亮</td>
          <td>7.0</td>
          <td>7.0</td>
          <td>1.0</td>
          <td>38.0</td>
          <td>16.0</td>
          <td>50.0</td>
          <td>4.0</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>较紧、尚弯曲、尚匀整、墨绿、尚净</td>
          <td>纯正</td>
          <td>纯和</td>
          <td>较黄绿尚亮</td>
          <td>黄绿尚亮</td>
          <td>7.0</td>
          <td>7.0</td>
          <td>1.0</td>
          <td>38.0</td>
          <td>16.0</td>
          <td>50.0</td>
          <td>4.0</td>
        </tr>
      </table>
      <!-- 西湖龙井 -->
      <p>西湖龙井</p>
      <table>
        <tr>
          <th rowspan="2">西湖龙井</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>整碎</th>
          <th>色泽</th>
          <th>净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>精品</td>
          <td>
            扁平光滑<br />
            挺秀尖削<br />
            芽锋显露
          </td>
          <td>匀齐</td>
          <td>嫩绿鲜润</td>
          <td>洁净</td>
          <td>嫩香馥郁持久</td>
          <td>鲜醇甘爽</td>
          <td>
            嫩绿鲜亮<br />
            清澈
          </td>
          <td>
            幼嫩成朵<br />
            匀齐、<br />
            嫩绿鲜亮
          </td>
        </tr>
        <tr>
          <td>特级</td>
          <td>
            扁平光润<br />
            挺直尖削
          </td>
          <td>匀齐</td>
          <td>嫩绿鲜润</td>
          <td>匀净</td>
          <td>清香持久</td>
          <td>鲜醇甘爽</td>
          <td>
            嫩绿明亮<br />
            清澈
          </td>
          <td>
            细嫩成朵<br />
            匀齐、<br />
            嫩绿明亮
          </td>
        </tr>
        <tr>
          <td>一级</td>
          <td>
            扁平光润<br />
            挺直
          </td>
          <td>匀整</td>
          <td>嫩绿尚鲜润</td>
          <td>洁净</td>
          <td>清香尚持久</td>
          <td>鲜醇爽口</td>
          <td>嫩绿明亮</td>
          <td>
            细嫩成朵<br />
            嫩绿明亮
          </td>
        </tr>
        <tr>
          <td>二级</td>
          <td>扁平尚光滑挺直</td>
          <td>匀整</td>
          <td>绿润</td>
          <td>较洁净</td>
          <td>清香</td>
          <td>尚鲜</td>
          <td>绿明亮</td>
          <td>
            尚细嫩成朵<br />
            绿明亮
          </td>
        </tr>
        <tr>
          <td>三级</td>
          <td>扁平、尚光滑尚挺直</td>
          <td>尚匀整</td>
          <td>尚绿润</td>
          <td>尚洁净</td>
          <td>尚清香</td>
          <td>尚醇</td>
          <td>尚绿明亮</td>
          <td>
            尚成朵<br />
            有嫩单片<br />
            浅绿且明亮
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>总灰分/%（质量分数）≤</th>
          <th>水浸出物/%（质量分数）≤</th>
          <th>粉末/%（质量分数）≤</th>
        </tr>
        <tr>
          <td>6.5</td>
          <td>6.5</td>
          <td>36.0</td>
          <td>1.0</td>
        </tr>
      </table>
      <!-- 顶谷大方 -->
      <p>顶谷大方</p>
      <table>
        <tr>
          <th rowspan="2">顶谷大方</th>
          <th colspan="2">外形</th>
          <th colspan="4">内质</th>
          <th>水分%≤</th>
          <th>总灰分/%≤</th>
          <th>碎末茶 %≤</th>
        </tr>
        <tr>
          <th>规格</th>
          <th>色泽</th>
          <th>香气</th>
          <th>汤色</th>
          <th>滋味</th>
          <th>叶底</th>
          <th rowspan="2">
            6.5 <br />
            (收购7.5）
          </th>
          <th rowspan="2">6.5</th>
          <th rowspan="2">1.0</th>
        </tr>
        <tr>
          <td></td>
          <td>扁平光滑尚挺直，匀整纯净显金毫，条长27-31mm左右</td>
          <td>嫩绿匀亮</td>
          <td>香气高长，合栗香成花香</td>
          <td>黄绿明亮</td>
          <td>鲜醇浓厚</td>
          <td>黄绿匀整</td>
        </tr>
      </table>

      <!-- 大佛龙井 -->
      <p>大佛龙井</p>
      <table>
        <tr>
          <th>大佛龙井</th>
          <th>特级</th>
          <th>一级</th>
          <th>二级</th>
          <th>三级</th>
          <th>四级</th>
          <th>五级</th>
        </tr>
        <tr>
          <td>外形</td>
          <td>扁平光润、挺直尖削；嫩绿鲜润；匀整重实；匀净</td>
          <td>扁平光滑尚润、挺直；嫩绿尚鲜润；匀整有锋；洁净</td>
          <td>扁平挺直；尚光滑；绿润；匀整尚洁净</td>
          <td>扁平、尚光滑；尚挺直；尚匀整尚洁净</td>
          <td>扁平；稍有宽扁条绿稍深；尚匀；稍有青黄片</td>
          <td>尚扁平；有宽扁条深绿较暗；尚整；有青壳碎片</td>
        </tr>
        <tr>
          <td>香气</td>
          <td>清香持久</td>
          <td>清香尚持久</td>
          <td>清香</td>
          <td>尚清香</td>
          <td>纯正</td>
          <td>平和</td>
        </tr>
        <tr>
          <td>滋味</td>
          <td>鲜醇甘爽</td>
          <td>鲜醇爽口</td>
          <td>尚鲜</td>
          <td>尚醇</td>
          <td>尚醇</td>
          <td>尚纯正</td>
        </tr>
        <tr>
          <td>汤色</td>
          <td>嫩绿明亮、清澈</td>
          <td>嫩绿明亮</td>
          <td>绿明亮</td>
          <td>尚绿明亮</td>
          <td>黄绿明亮</td>
          <td>黄绿</td>
        </tr>
        <tr>
          <td>叶底</td>
          <td>芽叶细嫩成朵，匀齐，嫩绿明亮</td>
          <td>细嫩成朵，嫩绿明亮</td>
          <td>尚细嫩成朵，绿明亮</td>
          <td>尚成朵，有嫩单片；浅绿尚明亮</td>
          <td>尚嫩匀稍有青张；尚绿明</td>
          <td>尚嫩欠匀，稍有青张；绿稍深</td>
        </tr>
        <tr>
          <td>其他要求</td>
          <td colspan="6">
            无霉变，无劣变，无污染，无异味
            产品洁净，不得着色，不得夹杂非茶类物质，不含任何添加剂
          </td>
        </tr>
        <tr>
          <td>水分/% ≤</td>
          <td colspan="3">6.5</td>
          <td colspan="3">7.0</td>
        </tr>
        <tr>
          <td>总灰分/% ≤</td>
          <td colspan="3">6.5</td>
          <td colspan="3">7.0</td>
        </tr>
        <tr>
          <td>水浸出物/% ≥</td>
          <td colspan="6">36.0</td>
        </tr>
        <tr>
          <td>粉末和碎茶/% ≤</td>
          <td colspan="6">1.0</td>
        </tr>
      </table>

      <!-- 71-124 -->
      <p>敬亭绿雪</p>
      <table>
        <tr>
          <th rowspan="2">敬亭绿雪</th>
          <th colspan="11">要求</th>
        </tr>
        <tr>
          <th>外形</th>
          <th>汤色</th>
          <th>香气</th>
          <th>滋味</th>
          <th>叶底</th>
          <th>水分/%≤</th>
          <th>总灰分/%≤</th>
          <th>水浸出物/%≤</th>
          <th>碎末茶/%</th>
          <th>氨基酸/%</th>
          <th>粗纤维/%</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>形如雀舌 嫩绿油润</td>
          <td>嫩绿 明亮</td>
          <td>嫩香持久</td>
          <td>嫩爽</td>
          <td>嫩绿匀净明亮</td>
          <td rowspan="4">≤7.0</td>
          <td rowspan="4">≤6.5</td>
          <td rowspan="4">≥36.0</td>
          <td>≤1.5</td>
          <td rowspan="2">≥2.0</td>
          <td rowspan="2">≤14.0</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>挺直略扁 绿匀齐</td>
          <td>绿明亮</td>
          <td>清香持久</td>
          <td>鲜爽</td>
          <td>嫩匀成朵 绿明亮</td>
          <td>≤3.0</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>形直尚挺 绿</td>
          <td>绿明亮</td>
          <td>清香</td>
          <td>醇爽</td>
          <td>芽叶成朵 绿明亮</td>
          <td rowspan="2">≤4.0</td>
          <td rowspan="2">≥1.5</td>
          <td rowspan="2">≤16.0</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>条索松直 尚绿</td>
          <td>黄绿明亮</td>
          <td>尚清香</td>
          <td>浓醇</td>
          <td>黄绿尚亮</td>
        </tr>
      </table>
      <table>
        <tr>
          <th colspan="8">指标 mg/kg</th>
        </tr>
        <tr>
          <th>铅（以Pb计）</th>
          <th>铜（以Cu计）</th>
          <th>六六六（BHC）</th>
          <th>滴滴涕（DDT）</th>
          <th>三氯杀螨醇（dicofol)</th>
          <th>氰戊菊酯（fenvalerate)</th>
          <th>联苯菊酯（biphenthrin)</th>
          <th>氯氰菊酯（cypermethrin)</th>
        </tr>
        <tr>
          <td>≤5</td>
          <td>≤60</td>
          <td>≤0.2</td>
          <td>≤0.2</td>
          <td>≤0.1</td>
          <td>≤0.1</td>
          <td>≤5</td>
          <td>≤0.5</td>
        </tr>
      </table>
      <table>
        <tr>
          <th colspan="7">指标 mg/kg</th>
        </tr>
        <tr>
          <th>溴氰菊酯（deltamethrin)</th>
          <th>甲胺磷（methamidophos)</th>
          <th>乙酰甲胺磷（acephate)</th>
          <th>乐果（dimethoate)</th>
          <th>敌敌畏（dichlorovos)</th>
          <th>杀螟硫磷（fenltrothion)</th>
          <th>喹硫磷（quintozene)</th>
        </tr>
        <tr>
          <td>≤5</td>
          <td>≤0.1</td>
          <td>≤0.1</td>
          <td>≤1</td>
          <td>≤0.1</td>
          <td>≤0.5</td>
          <td>≤0.2</td>
        </tr>
      </table>

      <p>天柱剑毫</p>
      <table>
        <tr>
          <th rowspan="2">天柱剑毫</th>
          <th colspan="3">要求</th>
          <th rowspan="2">外形</th>
          <th colspan="4">内质</th>
          <th>水分%</th>
          <th>碎末茶%</th>
          <th>总灰分%</th>
          <th>水浸出物%</th>
          <th>粗纤维%</th>
        </tr>
        <tr>
          <th>芽叶嫩度及比例（%）</th>
          <th>芽叶质量要求</th>
          <th>叶色</th>
          <th>汤色</th>
          <th>香气</th>
          <th>滋味</th>
          <th>叶底</th>
          <td rowspan="4">≤6.5</td>
          <td rowspan="4">≤3.0</td>
          <td rowspan="4">≤6.5</td>
          <td rowspan="4">≤35.0</td>
          <td rowspan="4">≤10.0</td>
        </tr>
        <tr>
          <td>特级</td>
          <td>一芽一叶初展≥70 一芽一叶开展≤30</td>
          <td>柔嫩、肥壮、茸毛多、节间短</td>
          <td>黄绿</td>
          <td>扁平挺直如剑 色泽翠绿鲜活白毫显露</td>
          <td>嫩绿明亮</td>
          <td>清香高长</td>
          <td>鲜醇爽口</td>
          <td>嫩绿明亮匀壮</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>一芽一叶初展≥30 一芽一叶开展≤70</td>
          <td>柔嫩、肥壮、茸毛多、节间较短</td>
          <td>黄绿</td>
          <td>扁平挺直如剑 色泽翠绿鲜活显毫</td>
          <td>绿亮</td>
          <td>清香（或嫩香）持久</td>
          <td>鲜浓</td>
          <td>绿亮匀整</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>一芽一叶开展≥70 一芽一叶初展≤30</td>
          <td>较柔嫩、肥壮、有茸毛、节间短</td>
          <td>绿</td>
          <td>扁平直似剑 色泽翠绿隐毫</td>
          <td>浅绿尚亮</td>
          <td>清香（或栗香）</td>
          <td>浓醇</td>
          <td>绿尚亮较匀整</td>
        </tr>
      </table>

      <p>秀眉</p>
      <table>
        <tr>
          <th>秀眉</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th></th>
          <th>条索</th>
          <th>整碎</th>
          <th>色泽</th>
          <th>净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>秀眉特级</td>
          <td>嫩茎细条</td>
          <td>匀称</td>
          <td>黄绿</td>
          <td>带细梗</td>
          <td>尚高</td>
          <td>浓尚醇</td>
          <td>黄绿尚明亮</td>
          <td>尚嫩匀 黄绿明亮</td>
        </tr>
        <tr>
          <td>秀眉一级</td>
          <td>筋条带片</td>
          <td>尚匀</td>
          <td>绿黄</td>
          <td>有细梗</td>
          <td>纯正</td>
          <td>浓带涩</td>
          <td>黄绿</td>
          <td>尚软尚匀绿黄</td>
        </tr>
        <tr>
          <td>秀眉二级</td>
          <td>片带筋条</td>
          <td>尚匀</td>
          <td>黄</td>
          <td>稍带轻片</td>
          <td>稍粗</td>
          <td>稍粗涩</td>
          <td>黄</td>
          <td>稍粗绿黄</td>
        </tr>
        <tr>
          <td>秀眉三级</td>
          <td>片形</td>
          <td>尚匀</td>
          <td>黄稍枯</td>
          <td>有轻片</td>
          <td>粗</td>
          <td>粗带涩</td>
          <td>黄稍暗</td>
          <td>较粗黄暗</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>总灰分/%（质量分数）≤</th>
          <th>粉末/%（质量分数）≤</th>
          <th>水浸出物/%（质量分数）≤</th>
          <th>粗纤维/%（质量分数）≤</th>
          <th>酸不溶性灰分/%（质量分数）≤</th>
          <th>水溶性灰分，占总灰分/%（质量分数）≥</th>
          <th>水溶性灰分碱度（以KOH计）/%（质量分数）</th>
          <th>茶多酚/%（质量分数）≥</th>
          <th>儿茶素/%（质量分数）≥</th>
        </tr>
        <tr>
          <td>7.0</td>
          <td>7.5</td>
          <td>1.5</td>
          <td>34.0</td>
          <td>16.5</td>
          <td>1.0</td>
          <td>45</td>
          <td>≥1.0a;≤3.1a</td>
          <td>12</td>
          <td>8</td>
        </tr>
        <tr>
          <td colspan="10">
            注:粗纤维、酸不溶性成分、水溶性灰分、水溶性碱度为参考指标。
          </td>
        </tr>
        <tr>
          <td colspan="10">
            当以每100g磨碎样品的毫克当量表示水溶灰分碱度时，其限量为:最小值17.8；最大值53.6。
          </td>
        </tr>
      </table>

      <p>绿片</p>
      <table>
        <tr>
          <th>绿片</th>
          <th>指标</th>
        </tr>
        <tr>
          <td>成品绿片茶含水率（质量分数）%</td>
          <td>≤5.5</td>
        </tr>
        <tr>
          <td>茶叶落地率 %</td>
          <td>≤1.5</td>
        </tr>
        <tr>
          <td>含梗率 %</td>
          <td>≤3</td>
        </tr>
      </table>

      <p>雨花茶</p>
      <table>
        <tr>
          <th rowspan="2">雨花茶</th>
          <th></th>
          <th rowspan="2">特级一等</th>
          <th rowspan="2">特级二等</th>
          <th rowspan="2">一级</th>
          <th rowspan="2">二级</th>
          <th></th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>条索</th>
          <th>色泽</th>
          <th>匀整度</th>
          <th>净度</th>
          <th>香气</th>
          <th>汤色</th>
          <th>滋味</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td rowspan="2">芽叶机械组成</td>
          <td>一芽一叶a/(%) ≥</td>
          <td>85</td>
          <td>75</td>
          <td>65</td>
          <td>50</td>
          <td>特级一等</td>
          <td>形似松针、紧细圆直 锋苗挺秀、白毫略显</td>
          <td>绿润</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>清香高长</td>
          <td>嫩绿明亮</td>
          <td>鲜醇爽口</td>
          <td>嫩绿明亮</td>
        </tr>
        <tr>
          <td>一芽一叶/(%) ≤</td>
          <td>15</td>
          <td>25</td>
          <td>35</td>
          <td>50</td>
          <td>特级二等</td>
          <td>形似松针、紧细圆直 白毫略显</td>
          <td>绿润</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>清香</td>
          <td>嫩绿明亮</td>
          <td>鲜醇</td>
          <td>嫩绿明亮</td>
        </tr>
        <tr>
          <td rowspan="3">芽叶长度组成</td>
          <td>2.0cm-2.5cm/（%）≥</td>
          <td>90</td>
          <td>80</td>
          <td>70</td>
          <td>60</td>
          <td>一级</td>
          <td>形似松针 紧直 略含扁条</td>
          <td>绿尚润</td>
          <td>尚匀整</td>
          <td>洁净</td>
          <td>尚清香</td>
          <td>绿明亮</td>
          <td>醇尚鲜</td>
          <td>绿明亮</td>
        </tr>
        <tr>
          <td>2.6cm-3.0cm/（%）≤</td>
          <td>10</td>
          <td>15</td>
          <td>25</td>
          <td>30</td>
          <td>二级</td>
          <td>形似松针 尚紧直 含扁条</td>
          <td>绿</td>
          <td>尚匀整</td>
          <td>洁净</td>
          <td>尚清香</td>
          <td>绿尚亮</td>
          <td>尚醇鲜</td>
          <td>绿尚亮</td>
        </tr>
        <tr>
          <td>3.0cm以上/（%）≤</td>
          <td></td>
          <td>5</td>
          <td>5</td>
          <td>10</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="15">a 一芽一叶中含有一定量的单芽</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水浸出物 %</th>
          <th>水分 %</th>
          <th>总灰分 %</th>
          <th>粗纤维 %</th>
          <th>碎末茶 %</th>
        </tr>
        <tr>
          <td rowspan="5">35.0</td>
          <td rowspan="5">7.0</td>
          <td rowspan="5">6.5</td>
          <td rowspan="5">14.0</td>
          <td rowspan="5">6.0</td>
        </tr>
      </table>

      <p>松针信阳毛尖</p>
      <table>
        <tr>
          <th rowspan="2">松针信阳毛尖</th>
          <th rowspan="2">芽叶组成</th>
          <th rowspan="2">采期</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>色泽</th>
          <th>整碎</th>
          <th>净度</th>
          <th>汤色</th>
          <th>香气</th>
          <th>滋味</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>珍品</td>
          <td>85%以上为单芽，其余为一芽一叶初展</td>
          <td>春季</td>
          <td>紧秀圆直</td>
          <td>嫩绿多白毫</td>
          <td>匀整</td>
          <td>净</td>
          <td>嫩绿透亮</td>
          <td>嫩香持久</td>
          <td>鲜爽</td>
          <td>嫩绿鲜活 匀亮</td>
        </tr>
        <tr>
          <td>特级</td>
          <td>85%以上为一芽一叶初展，其余为一芽一叶</td>
          <td>春季</td>
          <td>细圆紧尚直</td>
          <td>嫩绿显白毫</td>
          <td>匀整</td>
          <td>净</td>
          <td>嫩绿明亮</td>
          <td>清香高长</td>
          <td>鲜爽</td>
          <td>嫩绿明亮 匀亮</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>70%以上为一芽一叶，其余为一芽二叶初展</td>
          <td>春季</td>
          <td>圆尚直 尚紧细</td>
          <td>绿润有白毫</td>
          <td>较匀整</td>
          <td>净</td>
          <td>绿明亮</td>
          <td>栗香或清香</td>
          <td>醇厚</td>
          <td>绿尚亮 尚匀整</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>60%以上一芽二叶初展，其余为一芽二叶或同等嫩度的对夹叶</td>
          <td>春季</td>
          <td>尚直较紧</td>
          <td>尚绿润稍有白毫</td>
          <td>较匀整</td>
          <td>尚净</td>
          <td>绿尚亮</td>
          <td>纯正</td>
          <td>较醇厚</td>
          <td>绿 较匀整</td>
        </tr>
        <tr>
          <td rowspan="2">三级</td>
          <td>60%以上为一芽二叶，其余为同等嫩度的单叶、对夹叶或一芽三叶</td>
          <td>春季</td>
          <td rowspan="2">尚紧直</td>
          <td rowspan="2">深绿</td>
          <td rowspan="2">尚匀整</td>
          <td rowspan="2">尚净</td>
          <td rowspan="2">黄绿尚亮</td>
          <td rowspan="2">纯正</td>
          <td rowspan="2">较浓</td>
          <td rowspan="2">绿 较匀</td>
        </tr>
        <tr>
          <td>60%以上为一芽一叶，其余为一芽二叶或同等嫩度的对夹叶</td>
          <td>夏冬季</td>
        </tr>
        <tr>
          <td>四级</td>
          <td>60%以上为一芽二叶，其余为一芽三叶及同等嫩度的单叶或对夹叶</td>
          <td>夏冬季</td>
          <td>尚紧直</td>
          <td>深绿</td>
          <td>尚匀整</td>
          <td>稍有茎片</td>
          <td>黄绿</td>
          <td>尚纯正</td>
          <td>浓略涩</td>
          <td>绿欠亮</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/% ≤</th>
          <th>总灰分/% ≤</th>
          <th>粉末/% ≤</th>
          <th>水浸出物/% ≥</th>
          <th>粗纤维/% ≤</th>
        </tr>
        <tr>
          <td rowspan="2">6.5</td>
          <td rowspan="2">6.5</td>
          <td rowspan="2">2.0</td>
          <td>36.0</td>
          <td>12.0</td>
        </tr>
        <tr>
          <td>12.0</td>
          <td>14.0</td>
        </tr>
      </table>

      <!-- 表格数据绿茶126-179 -->
      <p>庐山云雾</p>
      <table>
        <tr>
          <th rowspan="2">珠茶</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>色泽</th>
          <th>整碎</th>
          <th>净度</th>
          <th>滋味</th>
          <th>香气</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>紧细显锋苗</td>
          <td>绿润</td>
          <td>匀齐</td>
          <td>洁净</td>
          <td>鲜醇回甘</td>
          <td>清香持久</td>
          <td>嫩绿 明亮</td>
          <td>细嫩 匀整</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>紧细显锋苗</td>
          <td>尚绿润</td>
          <td>匀整</td>
          <td>净</td>
          <td>鲜厚</td>
          <td>清香</td>
          <td>绿 明亮</td>
          <td>嫩匀</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>紧实</td>
          <td>绿</td>
          <td>尚匀整</td>
          <td>尚净</td>
          <td>尚纯</td>
          <td>尚清香</td>
          <td>绿 尚亮</td>
          <td>尚嫩</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>尚紧实</td>
          <td>深绿</td>
          <td>尚匀整</td>
          <td>有单张</td>
          <td>尚浓</td>
          <td>纯正</td>
          <td>黄绿 尚亮</td>
          <td>绿 尚匀</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>总灰分/%（质量分数）≤</th>
          <th>碎末茶/%（质量分数）≤</th>
        </tr>
        <tr>
          <td>7.0</td>
          <td>6.5</td>
          <td>5.0</td>
        </tr>
      </table>
      <p>绿牡丹</p>
      <table>
        <tr>
          <th rowspan="2" style="width: 50px">绿牡丹</th>
          <th rowspan="2">要求</th>
          <th rowspan="2">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>香气</th>
          <th>汤色</th>
          <th style="width: 50px">滋味</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>一级</td>
          <td>
            单芽和一芽一叶初展占95%以上，芽叶肥壮。匀净新鲜，不含紫芽、病虫芽叶、对夹叶、鱼叶、鳞片等
          </td>
          <td>茶芽挺直、肥壮显毫、匀净、翠绿</td>
          <td>馥郁持久</td>
          <td>嫩绿、清澈明亮</td>
          <td>鲜爽</td>
          <td>肥嫩、匀齐、翠绿明亮</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>
            一芽一叶占80%以内，一芽一叶初展占20%以上，芽叶肥壮，匀净新鲜，不含紫芽、病虫芽叶、对夹叶、鱼叶、鳞片等
          </td>
          <td>芽直、壮实有毫、匀净、翠绿</td>
          <td>鲜嫩持久</td>
          <td>嫩绿明亮</td>
          <td>鲜醇</td>
          <td>嫩匀成朵、嫩绿明亮</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>
            一芽一叶占80%以内，一芽二叶初展不超过20%，芽叶完整，匀净新鲜，不含紫芽、病虫芽叶和对夹叶
          </td>
          <td>条尚直、尚壮实有毫、匀整、绿润</td>
          <td>清高</td>
          <td>绿明亮</td>
          <td>醇爽</td>
          <td>绿亮、柔软成朵</td>
        </tr>
        <tr>
          <td>四级</td>
          <td>一芽二叶初展为主，一芽一叶不超过20%，芽叶完整、新鲜。</td>
          <td>紧实有毫、尚匀整、深绿</td>
          <td>清香</td>
          <td>黄绿明亮</td>
          <td>醇正</td>
          <td>绿亮柔软、带单叶</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>粉末含量/%（质量分数）≤</th>
          <th>总灰分含量/%（质量分数）≤</th>
          <th>粗纤维/%（质量分数）≤</th>
          <th>水浸出物含量/%（质量分数）≥</th>
        </tr>
        <tr>
          <td>6.5</td>
          <td>1.0</td>
          <td>6.5</td>
          <td>11.0</td>
          <td>37.0</td>
        </tr>
        <tr>
          <td>7.0</td>
          <td>1.5</td>
          <td>7.0</td>
          <td>14.0</td>
          <td>35.0</td>
        </tr>
      </table>
      <p>菊花茶</p>
      <table>
        <tr>
          <th style="width: 100px">菊花茶</th>
          <th>外观</th>
          <th>汤色</th>
          <th>香气</th>
          <th>滋味</th>
          <th>水分</th>
        </tr>
        <tr>
          <td></td>
          <td>具有各产品固有的色泽，无劣变、雾变，无杂质</td>
          <td>具有各产品固有的汤色</td>
          <td>具有各产品固有的香气，无异气味</td>
          <td>具有各产品固有的滋味，无异味</td>
          <td>花类≤13%</td>
        </tr>
      </table>
      <p>舒城小兰花</p>
      <table>
        <tr>
          <th style="width: 100px">舒城小兰花</th>
          <th></th>
          <th>外形</th>
          <th>香气</th>
          <th>汤色</th>
          <th>滋味</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特一级</td>
          <td>一芽一叶初展≥90%</td>
          <td>成朵翠绿白毫显露</td>
          <td>兰花香清香高长、鲜爽</td>
          <td>浅绿回甜</td>
          <td>鲜醇回甜</td>
          <td>嫩绿明亮、匀整成朵</td>
        </tr>
        <tr>
          <td>特二级</td>
          <td>一芽一叶初展≥50%、一芽一叶≤50%</td>
          <td>成朵翠绿显毫</td>
          <td>嫩清香高长、鲜爽</td>
          <td>嫩绿清澈</td>
          <td>醇厚鲜爽</td>
          <td>嫩黄绿明亮、成朵较匀整</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>一芽一叶≥70%、一芽二叶初展≤30%</td>
          <td>芽叶相连翠绿、较匀润显毫</td>
          <td>清香鲜爽较持久</td>
          <td>嫩绿明亮</td>
          <td>醇厚较鲜爽</td>
          <td>绿明亮、尚匀整成朵</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>一芽二叶初展≥50%、一芽二叶≤50%</td>
          <td>舒展似兰花初放、绿尚显毫</td>
          <td>清香较持久</td>
          <td>黄绿较明亮</td>
          <td>较鲜醇</td>
          <td>黄绿尚亮</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>一芽二叶≥30%、一芽三叶初展≤70%</td>
          <td>舒展色绿、微显毫</td>
          <td>栗香持久</td>
          <td>黄绿尚明亮</td>
          <td>尚鲜醇</td>
          <td>黄绿</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分 ≤</th>
          <th>碎末 ≤</th>
          <th>水浸出物 ≥</th>
          <th>总灰分 ≤</th>
        </tr>
        <tr>
          <td>7.0</td>
          <td>5.0</td>
          <td>34.0</td>
          <td>6.5</td>
        </tr>
      </table>
      <table>
        <tr>
          <th></th>
          <th>铅（mg/kg 以Pb计）</th>
          <th>铜（以Cu计）</th>
          <th>每100g大肠菌群，个</th>
          <th>敌敌畏(mg/kg)</th>
          <th>乐果（mg/kg)</th>
          <th>杀螟硫磷（mg/kg)</th>
          <th>喹硫磷（mg/kg)</th>
          <th>氯氰菊酯（mg/kg)</th>
          <th>溴氰菊酯（mg/kg)</th>
          <th>联苯菊酯（mg/kg）</th>
        </tr>
        <tr>
          <td>指标 mg/kg</td>
          <td>≤5</td>
          <td>≤60</td>
          <td>≤300</td>
          <td>≤0.1</td>
          <td>≤1.0</td>
          <td>≤0.5</td>
          <td>≤0.2</td>
          <td>≤0.5</td>
          <td>≤5.0</td>
          <td>≤5.0</td>
        </tr>
      </table>
      <p>黄山毛峰</p>
      <table>
        <tr>
          <th style="width: 100px" rowspan="2">黄山毛峰</th>
          <th rowspan="2">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>香气</th>
          <th>汤色</th>
          <th>滋味</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特级一等</td>
          <td>芽头肥壮，匀齐，形似雀舌，毫显，嫩绿泛象牙色，有金黄片</td>
          <td>嫩香馥郁持久</td>
          <td>嫩绿 清澈鲜亮</td>
          <td>鲜醇爽回甘</td>
          <td>嫩黄匀亮鲜活</td>
        </tr>
        <tr>
          <td>特级二等</td>
          <td>芽头较肥壮，较匀齐，形似雀舌，毫显，嫩绿润</td>
          <td>嫩香高长</td>
          <td>嫩绿 清澈明亮</td>
          <td>鲜醇爽</td>
          <td>嫩黄，明亮</td>
        </tr>
        <tr>
          <td>特级三等</td>
          <td>芽头尚肥壮，尚匀齐，毫显，绿润</td>
          <td>嫩香</td>
          <td>嫩绿明亮</td>
          <td>较鲜醇爽</td>
          <td>嫩黄，明亮</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>芽叶肥壮，匀齐隐毫，条微卷、绿润</td>
          <td>清香</td>
          <td>嫩黄绿亮</td>
          <td>鲜醇</td>
          <td>较嫩匀黄绿亮</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>芽叶较肥壮，较匀整，条微卷，显芽毫，较绿润</td>
          <td>清香</td>
          <td>黄绿亮</td>
          <td>醇厚</td>
          <td>尚嫩匀黄绿亮</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>芽叶尚肥壮，条微卷，尚匀，尚绿润</td>
          <td>清香</td>
          <td>黄绿尚亮</td>
          <td>尚醇厚</td>
          <td>尚匀，黄绿</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/% ≤</th>
          <th>总灰分/% ≤</th>
          <th>粉末/% ≤</th>
          <th>水浸出物/% ≥</th>
        </tr>
        <tr>
          <td>6.5</td>
          <td>6.5</td>
          <td>0.5</td>
          <td>35.0</td>
        </tr>
      </table>
      <table>
        <tr>
          <th></th>
          <th>铅（mg/kg 以Pb计）</th>
          <th>稀土/（mg/kg）</th>
          <th>氯菊酯</th>
          <th>氯氰菊酯</th>
          <th>溴氰菊酯</th>
          <th>六六六（BHC)</th>
          <th>滴滴涕（DDT）</th>
          <th>顺式氰戊菊酯</th>
          <th>氟氰戊菊酯</th>
          <th>乙酰甲胺磷</th>
          <th>杀螟硫磷</th>
        </tr>
        <tr>
          <td>指标 mg/kg</td>
          <td>≤5.0</td>
          <td>≤2.0</td>
          <td>≤20</td>
          <td>≤20</td>
          <td>≤10</td>
          <td>≤0.20</td>
          <td>≤0.20</td>
          <td>≤2</td>
          <td>≤20</td>
          <td>≤0.1</td>
          <td>≤0.5</td>
        </tr>
      </table>
      <p>华山银毫</p>
      <table>
        <tr>
          <th style="width: 100px">华山银毫</th>
          <th>芽叶机械组成</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>一芽一叶初展占60%以上，其余为一芽一叶</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>一芽二叶初展占60%以上，其余为一芽二叶或同等嫩度的对夹叶</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>一芽二叶占60%以上，其余为同等嫩度的单叶、对夹叶或一芽三叶</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>一芽二叶占50%以上，其余为一芽三叶及同等嫩度的单叶或对夹叶</td>
        </tr>
      </table>
      <p>岳西翠兰</p>
      <table>
        <tr>
          <th style="width: 100px" rowspan="2">岳西翠兰</th>
          <th rowspan="2">芽叶嫩度</th>
          <th rowspan="2">芽叶质量要求</th>
          <th rowspan="2" style="width: 80px">叶色</th>
          <th rowspan="2">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>汤色</th>
          <th>香气</th>
          <th>滋味</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特一级</td>
          <td>一芽一叶初展及以上</td>
          <td>芽头肥壮，匀齐</td>
          <td>嫩黄绿</td>
          <td>芽头壮实，色泽翠绿鲜活，匀净</td>
          <td>嫩绿明亮</td>
          <td>嫩香清鲜</td>
          <td>鲜爽回甘</td>
          <td>嫩黄绿匀亮</td>
        </tr>
        <tr>
          <td>特二级</td>
          <td>一芽一叶开展及以上</td>
          <td>芽叶完整，匀齐</td>
          <td>嫩绿</td>
          <td>芽叶肥壮，翠绿鲜润，匀净</td>
          <td>嫩绿明亮</td>
          <td>清香高爽</td>
          <td>鲜醇回甘</td>
          <td>嫩绿匀亮</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>一芽二叶初展为主</td>
          <td>芽叶完整，较匀齐</td>
          <td>绿</td>
          <td>芽叶成朵，舒直翠绿鲜润，较匀净</td>
          <td>绿、清亮</td>
          <td>清香高长</td>
          <td>醇厚回甘</td>
          <td>黄绿匀亮</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>一芽二叶开展为主</td>
          <td>芽叶完整，较匀齐</td>
          <td>绿</td>
          <td>芽叶肥壮，翠绿鲜润，匀净</td>
          <td>黄绿、明亮</td>
          <td>清香纯正</td>
          <td>醇厚</td>
          <td>黄绿较匀亮</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/% ≤</th>
          <th>水浸出物/% ≥</th>
          <th>总灰分/% ≤</th>
          <th>粗纤维/% ≤</th>
          <th>碎末茶/% ≤</th>
        </tr>
        <tr>
          <td>6.0</td>
          <td>36.0</td>
          <td>6.0</td>
          <td>14.0</td>
          <td>4.0</td>
        </tr>
      </table>

      <!-- 表格数据181-231 -->
      <p>霍山黄芽</p>
      <table>
        <tr>
          <th>霍山黄芽</th>
          <th>鲜叶组成</th>
          <th>外形</th>
          <th>色泽</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特一级</td>
          <td>一芽一叶初展≥90%</td>
          <td>雀舌匀齐</td>
          <td>嫩绿微黄批毫</td>
          <td>清香持久</td>
          <td>鲜爽回甘</td>
          <td>鲜爽回甘</td>
          <td>嫩黄绿鲜明</td>
        </tr>
        <tr>
          <td>特二级</td>
          <td>一芽一叶初展≥70%</td>
          <td>雀舌</td>
          <td>嫩绿微黄显毫</td>
          <td>清香持久</td>
          <td>鲜醇回甘</td>
          <td>嫩绿明亮</td>
          <td>嫩黄绿明亮</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>一芽一叶≥60%，一芽二叶初展≤40%</td>
          <td>形直尚匀齐</td>
          <td>色泽微黄白毫尚显</td>
          <td>清香尚持久</td>
          <td>醇尚甘</td>
          <td>黄绿清明</td>
          <td>绿微黄明亮</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>一芽二叶初展≥50%，一芽二叶≤50%</td>
          <td>形直微展</td>
          <td>色绿微黄有显</td>
          <td>清香</td>
          <td>尚鲜醇</td>
          <td>黄绿尚明</td>
          <td>黄绿尚匀</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>一芽二叶≥40%，一芽三叶初展≤60%</td>
          <td>尚直微展</td>
          <td>色绿微黄</td>
          <td>有清香</td>
          <td>醇和</td>
          <td>黄绿</td>
          <td>黄绿</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/% ≤</th>
          <th>碎末茶/% ≤</th>
          <th>水浸出物/% ≥</th>
          <th>粗纤维/% ≤</th>
          <th>粗总灰分/% ≤</th>
        </tr>
        <tr>
          <td rowspan="5">6.5</td>
          <td rowspan="5">4.0</td>
          <td rowspan="5">38</td>
          <td rowspan="5">14</td>
          <td rowspan="5">6.5</td>
        </tr>
      </table>
      <p>九华佛茶</p>
      <table>
        <tr>
          <th>九华佛茶</th>
          <th>外形</th>
          <th>内质</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>扁直匀齐，色泽翠绿光润</td>
          <td>花香或清香持久，汤色嫩绿，清澈明亮，滋味鲜爽甘醇</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>扁直较匀整，色泽翠绿较润</td>
          <td>清香持久，汤色嫩绿明亮，滋味鲜醇，叶底嫩绿较匀整，有光泽</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>较扁直尚匀整色泽绿尚润</td>
          <td>香气清高，汤色黄绿尚亮，滋味醇和，叶底黄绿尚匀整</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>总灰分含量/%（质量分数）≤</th>
          <th>碎末茶/%（质量分数）≤</th>
          <th>水浸出物含量/%（质量分数）≥</th>
          <th>粗纤维/%（质量分数）≤</th>
        </tr>
        <tr>
          <td rowspan="3">6.0</td>
          <td rowspan="3">6.5</td>
          <td rowspan="3">6.0</td>
          <td rowspan="3">37.0</td>
          <td rowspan="3">14.0</td>
        </tr>
      </table>
      <p>兰香</p>
      <table>
        <tr>
          <th rowspan="2">兰香</th>
          <th rowspan="2">采摘标准</th>
          <th colspan="3">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>形状</th>
          <th>色泽</th>
          <th>匀净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特级一等</td>
          <td>一芽一叶初展一芽一叶</td>
          <td>绣减形、平直较壮</td>
          <td>嫩绿匀润显毫</td>
          <td>匀整纯净</td>
          <td>嫩香馥郁持久</td>
          <td>鲜爽醇厚 回甘</td>
          <td>嫩绿、清澈鲜亮</td>
          <td>嫩绿鲜明肥壮匀整</td>
        </tr>
        <tr>
          <td>特级二等</td>
          <td>一芽一叶</td>
          <td>绣减形、平直舒展</td>
          <td>翠绿较润显毫</td>
          <td>匀整、较纯净</td>
          <td>清香高长</td>
          <td>鲜爽醇厚</td>
          <td>嫩绿、清澈明亮</td>
          <td>嫩绿较壮 匀整</td>
        </tr>
        <tr>
          <td>一级一等</td>
          <td>一芽一叶约50%一芽二叶初展约50%</td>
          <td>绣减形和直条形</td>
          <td>翠绿显毫</td>
          <td>较匀整</td>
          <td>香气纯正</td>
          <td>浓醇较爽</td>
          <td>浅绿、清澈较亮</td>
          <td>嫩黄绿 较匀整</td>
        </tr>
        <tr>
          <td>一级二等</td>
          <td>一芽二叶为主少量一芽三叶初展</td>
          <td>尚平直</td>
          <td>色绿有毫</td>
          <td>较匀整</td>
          <td>有香气</td>
          <td>纯和</td>
          <td>浅绿、尚亮</td>
          <td>黄绿 尚匀</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/% ≤</th>
          <th>粉末/% ≤</th>
          <th>总灰分/% ≤</th>
          <th>水浸出物/% ≥</th>
          <th>粗纤维/% ≤</th>
        </tr>
        <tr>
          <td rowspan="4">7.0</td>
          <td rowspan="4">0.5</td>
          <td rowspan="4">6.5</td>
          <td rowspan="4">37</td>
          <td rowspan="4">14.0</td>
        </tr>
      </table>

      <p>六安瓜片</p>
      <table>
        <tr>
          <th rowspan="2">六安瓜片</th>
          <th rowspan="2">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特一</td>
          <td>瓜子形、平伏、匀整、宝绿、上霜、显毫、无漂叶</td>
          <td>清香高长</td>
          <td>鲜爽回甘</td>
          <td>嫩绿清澈明亮</td>
          <td>嫩绿鲜活匀整</td>
        </tr>
        <tr>
          <td>特二</td>
          <td>瓜子形、匀整、宝绿、上霜、显毫、无漂叶</td>
          <td>清香持久</td>
          <td>鲜醇回甘</td>
          <td>嫩绿明亮</td>
          <td>嫩绿鲜活匀整</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>瓜子形、匀整、色绿、上霜、无漂叶</td>
          <td>清香</td>
          <td>鲜醇</td>
          <td>黄绿明亮</td>
          <td>黄绿匀整</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>瓜子形、较匀整、色绿、有霜、稍有漂叶</td>
          <td>纯正</td>
          <td>较鲜醇</td>
          <td>黄绿尚亮</td>
          <td>黄绿尚匀整</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>瓜子形、色绿、稍有漂叶</td>
          <td>纯正</td>
          <td>尚鲜醇</td>
          <td>黄绿尚亮</td>
          <td>黄绿尚匀整</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/% ≤</th>
          <th>粉末/% ≤</th>
          <th>总灰分/% ≤</th>
          <th>水浸出物/% ≥</th>
          <th>粗纤维/% ≤</th>
        </tr>
        <tr>
          <td rowspan="5">6.0</td>
          <td rowspan="5">1.0</td>
          <td rowspan="5">6.5</td>
          <td rowspan="5">36</td>
          <td rowspan="5">14</td>
        </tr>
      </table>
      <p>太平猴魁</p>
      <table>
        <tr>
          <th rowspan="2">太平猴魁</th>
          <th rowspan="2">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>汤色</th>
          <th>香气</th>
          <th>滋味</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>极品</td>
          <td>
            扁展挺直，魁伟壮实，两叶抱一芽，匀齐，毫多不显，苍绿匀润，部分主脉暗红
          </td>
          <td>嫩绿 清澈明亮</td>
          <td>鲜灵高爽兰花香持久</td>
          <td>鲜爽醇厚，回味甘甜，独具“猴韵”</td>
          <td>嫩匀肥壮，成朵，嫩黄绿明亮</td>
        </tr>
        <tr>
          <td>特级</td>
          <td>扁平壮实，两叶抱一芽，匀齐，毫多不显，苍绿匀润，部分主脉暗红</td>
          <td>嫩绿 明亮</td>
          <td>鲜嫩清高有兰花香</td>
          <td>鲜爽醇厚，回味甘甜，有“猴韵”</td>
          <td>嫩匀肥厚，成朵，嫩黄绿匀亮</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>
            扁平重实，两叶抱一芽，匀整，毫隐不显，苍绿较匀润，部分主脉暗红
          </td>
          <td>嫩黄绿 明亮</td>
          <td>清高</td>
          <td>鲜爽回甘</td>
          <td>鲜爽回甘</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>扁平，两叶抱一芽，少量单片，尚匀整，毫不显，绿润</td>
          <td>黄绿 明亮</td>
          <td>尚清高</td>
          <td>醇厚甘甜</td>
          <td>尚嫩匀，成朵，少量单片，黄绿明亮</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>两叶抱一芽，少数翘散，少量断穗，有毫，尚匀整，尚绿润</td>
          <td>黄绿 尚明亮</td>
          <td>清香</td>
          <td>醇厚</td>
          <td>尚嫩欠匀，成朵，少量断碎，黄绿亮</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/% ≤</th>
          <th>粉末/% ≤</th>
          <th>总灰分/% ≤</th>
          <th>水浸出物/% ≥</th>
          <th>粗纤维/% ≤</th>
        </tr>
        <tr>
          <td rowspan="5">6.5</td>
          <td rowspan="5">0.5</td>
          <td rowspan="5">6.5</td>
          <td rowspan="5">37</td>
          <td rowspan="5">14</td>
        </tr>
      </table>
      <p>日本眉茶</p>
      <table>
        <tr>
          <th rowspan="2">日本眉茶</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>整碎</th>
          <th>色泽</th>
          <th>净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特珍特级</td>
          <td>细紧显锋苗</td>
          <td>匀整</td>
          <td>绿润起霜</td>
          <td>洁净</td>
          <td>高香持久</td>
          <td>鲜浓醇厚</td>
          <td>绿明亮</td>
          <td>含芽嫩绿明亮</td>
        </tr>
        <tr>
          <td>特珍一级</td>
          <td>细紧显锋苗</td>
          <td>匀整</td>
          <td>绿润起霜</td>
          <td>净</td>
          <td>高香</td>
          <td>鲜浓醇</td>
          <td>绿明亮</td>
          <td>嫩匀嫩绿明亮</td>
        </tr>
        <tr>
          <td>特珍二级</td>
          <td>紧结</td>
          <td>尚匀整</td>
          <td>绿润</td>
          <td>尚净</td>
          <td>较高</td>
          <td>浓厚</td>
          <td>黄绿明亮</td>
          <td>嫩匀绿明亮</td>
        </tr>
        <tr>
          <td>珍眉一级</td>
          <td>紧实</td>
          <td>尚匀整</td>
          <td>绿尚润</td>
          <td>尚净</td>
          <td>尚高</td>
          <td>浓醇</td>
          <td>黄绿尚明亮</td>
          <td>尚嫩匀黄绿明亮</td>
        </tr>
        <tr>
          <td>珍眉二级</td>
          <td>尚紧实</td>
          <td>尚匀</td>
          <td>黄绿尚润</td>
          <td>稍有嫩茎</td>
          <td>纯正</td>
          <td>醇和</td>
          <td>黄绿</td>
          <td>尚匀软黄绿</td>
        </tr>
        <tr>
          <td>珍眉三级</td>
          <td>粗实</td>
          <td>尚匀</td>
          <td>绿黄</td>
          <td>带细梗</td>
          <td>平正</td>
          <td>平和</td>
          <td>绿黄</td>
          <td>尚软绿黄</td>
        </tr>
        <tr>
          <td>珍眉四级</td>
          <td>稍粗松</td>
          <td>欠匀</td>
          <td>黄</td>
          <td>带梗朴</td>
          <td>稍粗</td>
          <td>稍粗淡</td>
          <td>黄稍暗</td>
          <td>稍粗绿黄</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>总灰分/%（质量分数）≤</th>
          <th>粉末/%（质量分数）≤</th>
          <th>水浸出物/%（质量分数）≤</th>
          <th>粗纤维/%（质量分数）≤</th>
          <th>酸不溶性灰分/%（质量分数）≤</th>
          <th>水溶性灰分，占总灰分/%（质量分数）≥</th>
          <th>水溶性灰分碱度（以KOH计）/%（质量分数</th>
          <th>茶多酚/%（质量分数）≥</th>
          <th>儿茶素/%（质量分数）≥</th>
        </tr>
        <tr>
          <td>7.0</td>
          <td>7.5</td>
          <td>1.0</td>
          <td>36.0</td>
          <td>16.5</td>
          <td>1.0</td>
          <td>45</td>
          <td>≥1.0a;≤3.1a</td>
          <td>14</td>
          <td>9</td>
        </tr>
        <tr>
          <td colspan="10">
            注：粗纤维、酸不溶性成分、水溶性灰分、水溶性碱度为参考指标。
          </td>
        </tr>
        <tr>
          <td colspan="10">
            当以每100g磨碎样品的毫克当量表示水溶灰分碱度时，其限量为：最小值17.8；最大值53.6。
          </td>
        </tr>
      </table>
      <p>日本碾茶</p>
      <table>
        <tr>
          <th>日本碾茶</th>
          <th>指标</th>
        </tr>
        <tr>
          <td>成品碾茶含水率（质量分数） %</td>
          <td>≤5.0</td>
        </tr>
        <tr>
          <td>茶叶落地率 %</td>
          <td>≤0.8</td>
        </tr>
        <tr>
          <td>含梗率 %</td>
          <td>≤1.0</td>
        </tr>
      </table>

      <!-- 表格数据233-290 -->
      <p>恩施玉露</p>
      <!-- 恩施玉露 -->
      <table>
        <tr>
          <th rowspan="2">恩施玉露</th>
          <th colspan="5">项目</th>
        </tr>
        <tr>
          <th>外形</th>
          <th>汤色</th>
          <th>香气</th>
          <th>滋味</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>形似松针、色泽翠绿</td>
          <td>清澈、明亮</td>
          <td>清香持久</td>
          <td>鲜爽回甘</td>
          <td>嫩匀、明亮</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>紧细挺直、色泽绿润</td>
          <td>嫩绿、明亮</td>
          <td>清香尚持久</td>
          <td>鲜醇回甜</td>
          <td>绿、明亮</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>挺直、墨绿</td>
          <td>绿、明亮</td>
          <td>清香</td>
          <td>醇和</td>
          <td>绿、尚亮</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>水浸出物/%（质量分数）≥</th>
          <th>总灰分/%（质量分数）≤</th>
          <th>粗纤维/%（质量分数）≤</th>
          <th>粉末/%（质量分数）≤</th>
          <th>碎茶/%（质量分数）≥</th>
        </tr>
        <tr>
          <td>6.5</td>
          <td>36</td>
          <td>6.5</td>
          <td>14</td>
          <td>1.0</td>
          <td>2.0</td>
        </tr>
      </table>
      <table>
        <tr>
          <th colspan="14">指标 mg/kg</th>
        </tr>
        <tr>
          <th>铅(以Pb计) (mg/kg) ≤</th>
          <th>稀土 (mg/kg) ≤</th>
          <th>联苯菊酯 (mg/kg) ≤</th>
          <th>溴氰菊酯 (mg/kg) ≤</th>
          <th>顺式氰戊菊酯 (mg/kg) ≤</th>
          <th>氯菊酯 ≤</th>
          <th>氟氰戊菊酯 ≤</th>
          <th>乙酰甲胺磷 ≤</th>
          <th>乐果 (mg/kg) ≤</th>
          <th>敌敌畏 ≤</th>
          <th>杀螟硫磷 (mg/kg） ≤</th>
          <th>喹硫磷 (mg/kg) ≤</th>
          <th>每100g大肠菌群/（个） ≤</th>
        </tr>
        <tr>
          <td>5.0</td>
          <td>2.0</td>
          <td>0.5</td>
          <td>5.0</td>
          <td>5.0</td>
          <td>2.0</td>
          <td>20.0</td>
          <td>20.0</td>
          <td>0.1</td>
          <td>0.1</td>
          <td>0.1</td>
          <td>0.2</td>
          <td>300</td>
        </tr>
        <tr>
          <td colspan="14">注：稀土以稀土氧化物总量计</td>
        </tr>
      </table>

      <p>日本煎茶</p>
      <table>
        <tr>
          <th rowspan="2">日本煎茶</th>
          <th colspan="6">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>色泽</th>
          <th>嫩度</th>
          <th>净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>EEE</td>
          <td>细紧 挺削</td>
          <td>绿 乌润</td>
          <td>嫩芽多</td>
          <td>匀净</td>
          <td>嫩香持久</td>
          <td>浓 鲜醇</td>
          <td>嫩绿 清澈 明亮</td>
          <td>嫩绿 明亮 匀净</td>
        </tr>
        <tr>
          <td>EE</td>
          <td>细紧 挺削</td>
          <td>绿 乌润</td>
          <td>嫩芽多</td>
          <td>尚匀净</td>
          <td>清香持久</td>
          <td>鲜爽</td>
          <td>尚嫩绿清澈明亮</td>
          <td>嫩绿明亮</td>
        </tr>
        <tr>
          <td>E</td>
          <td>紧直</td>
          <td>尚绿 乌润</td>
          <td>嫩芽尚多</td>
          <td>稍有嫩茎</td>
          <td>清香</td>
          <td>尚鲜爽</td>
          <td>黄绿明亮</td>
          <td>尚嫩绿 明亮</td>
        </tr>
        <tr>
          <td>S</td>
          <td>尚紧直</td>
          <td>尚绿</td>
          <td>稍有嫩芽</td>
          <td>略有嫩茎</td>
          <td>尚清香</td>
          <td>鲜醇</td>
          <td>绿尚亮</td>
          <td>尚嫩绿明亮</td>
        </tr>
        <tr>
          <td>1</td>
          <td>扁直</td>
          <td>绿尚润</td>
          <td>有嫩芽</td>
          <td>有嫩茎叶</td>
          <td>纯正</td>
          <td>醇和</td>
          <td>绿尚明</td>
          <td>绿 尚明</td>
        </tr>
        <tr>
          <td>2</td>
          <td>尚扁直</td>
          <td>绿</td>
          <td>稍有嫩叶</td>
          <td>稍有嫩梗叶</td>
          <td>尚纯</td>
          <td>平和</td>
          <td>绿</td>
          <td>绿 欠明</td>
        </tr>
        <tr>
          <td>3</td>
          <td>稍粗松</td>
          <td>绿 稍枯</td>
          <td>稍有老叶</td>
          <td>有梗朴叶</td>
          <td>尚纯</td>
          <td>尚平和</td>
          <td>绿 欠明</td>
          <td>尚绿 带黄</td>
        </tr>
        <tr>
          <td>4</td>
          <td>稍粗松</td>
          <td>枯</td>
          <td>有老叶</td>
          <td>有老梗朴叶</td>
          <td>稍粗</td>
          <td>稍粗味</td>
          <td>绿稍暗</td>
          <td>粗老</td>
        </tr>
        <tr>
          <td>5</td>
          <td>较粗松</td>
          <td>枯暗</td>
          <td>老叶较多</td>
          <td>老梗朴叶较多</td>
          <td>有粗气</td>
          <td>稍淡</td>
          <td>绿稍暗 带黄</td>
          <td>粗老 稍暗</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>总灰分/%（质量分数）≤</th>
        </tr>
        <tr>
          <td>7.0</td>
          <td>6.5</td>
        </tr>
      </table>

      <p>茉莉花茶</p>
      <table>
        <tr>
          <th rowspan="2">茉莉花茶</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>整碎</th>
          <th>净度</th>
          <th>色泽</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>细紧或肥壮 有锋苗有毫</td>
          <td>匀整</td>
          <td>净</td>
          <td>绿黄润</td>
          <td>鲜浓持久</td>
          <td>浓醇爽</td>
          <td>黄亮</td>
          <td>嫩软匀齐 黄绿明亮</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>紧结有锋苗</td>
          <td>匀整</td>
          <td>尚净</td>
          <td>绿黄尚润</td>
          <td>鲜浓</td>
          <td>浓醇</td>
          <td>黄明</td>
          <td>嫩匀 黄绿明亮</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>尚紧结</td>
          <td>尚匀整</td>
          <td>稍有嫩茎</td>
          <td>绿黄</td>
          <td>尚鲜浓</td>
          <td>尚浓醇</td>
          <td>黄尚亮</td>
          <td>嫩尚匀 黄绿亮</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>尚紧</td>
          <td>尚匀整</td>
          <td>有嫩茎</td>
          <td>尚绿黄</td>
          <td>尚浓</td>
          <td>醇和</td>
          <td>黄尚明</td>
          <td>尚嫩 匀黄绿</td>
        </tr>
        <tr>
          <td>四级</td>
          <td>稍松</td>
          <td>尚匀</td>
          <td>有茎梗</td>
          <td>黄稍暗</td>
          <td>香薄</td>
          <td>尚醇和</td>
          <td>黄欠亮</td>
          <td>稍有摊张绿黄</td>
        </tr>
        <tr>
          <td>五级</td>
          <td>稍粗松</td>
          <td>尚匀</td>
          <td>有梗朴</td>
          <td>黄稍枯</td>
          <td>香弱</td>
          <td>稍粗</td>
          <td>黄较暗</td>
          <td>稍粗大黄稍暗</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>总灰分/%（质量分数）≤</th>
          <th>粉末/%（质量分数）≤</th>
          <th>水浸出物/%（质量分数）≥</th>
          <th>茉莉花干/%（质量分数）≤</th>
        </tr>
        <tr>
          <td rowspan="2">8.5</td>
          <td rowspan="2">6.5</td>
          <td>1.0</td>
          <td>34.0</td>
          <td>1.0</td>
        </tr>
        <tr>
          <td>1.2</td>
          <td>32.0</td>
          <td>1.5</td>
        </tr>
      </table>

      <p>珠兰花茶</p>
      <table>
        <tr>
          <th rowspan="2">珠兰花茶</th>
          <th rowspan="2">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>香气</th>
          <th>汤色</th>
          <th>滋味</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>条索紧细，锋苗显露匀整，匀净，深绿润</td>
          <td>兰香幽雅持久</td>
          <td>嫩黄清亮</td>
          <td>醇爽较鲜</td>
          <td>嫩匀柔软明亮</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>条索紧细，显锋苗，匀整平伏，绿润</td>
          <td>兰香幽长</td>
          <td>黄绿清澈明亮</td>
          <td>醇浓甘爽</td>
          <td>细嫩匀整，明亮</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>条索紧结，尚匀整，尚净，黄绿润</td>
          <td>兰香幽雅</td>
          <td>绿黄亮</td>
          <td>浓醇带鲜</td>
          <td>嫩尚匀亮</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>条索尚紧平伏，尚匀整，尚净，稍含嫩茎，黄绿稍润</td>
          <td>兰香较幽雅</td>
          <td>绿黄尚亮</td>
          <td>醇厚</td>
          <td>尚嫩亮</td>
        </tr>
        <tr>
          <td>四级</td>
          <td>条索壮实稍露筋梗，尚匀整，尚净，有茎梗，黄绿稍润</td>
          <td>兰香显</td>
          <td>黄尚亮</td>
          <td>尚醇厚</td>
          <td>尚软有摊张</td>
        </tr>
        <tr>
          <td>五级</td>
          <td>条索稍粗略扁含梗片、尚匀、有梗朴、黄稍枯暗</td>
          <td>兰香尚显</td>
          <td>黄稍暗</td>
          <td>尚纯</td>
          <td>稍粗老</td>
        </tr>
        <tr>
          <td>碎茶</td>
          <td colspan="5">
            通过紧门筛（筛网孔径0.8mm-1.6mm)洁净重实的颗粒茶，有兰香，滋味尚醇
          </td>
        </tr>
        <tr>
          <td>片茶</td>
          <td colspan="5">
            通过紧门筛（筛网孔径0.8mm-1.6mm)轻质片状茶，有兰香，滋味尚纯
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分/%（质量分数）≤</th>
          <th>总灰分/%（质量分数）≤</th>
          <th>粉末/%（质量分数）≤</th>
          <th>水浸出物/%（质量分数）</th>
        </tr>
        <tr>
          <td rowspan="2">8.5</td>
          <td rowspan="2">6.5</td>
          <td>1.0</td>
          <td>34.0</td>
        </tr>
        <tr>
          <td>1.2</td>
          <td rowspan="3">32.0</td>
        </tr>
        <tr>
          <td></td>
          <td rowspan="2">7.0</td>
          <td>3.0</td>
        </tr>
        <tr>
          <td></td>
          <td>7.0</td>
        </tr>
      </table>

      <p>茉莉绿片</p>
      <!-- 茉莉绿片 -->
      <table>
        <tr>
          <th rowspan="9">茉莉绿片</th>
          <th rowspan="2">级别（特种烘青茉莉花茶）</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>形状</th>
          <th>整碎</th>
          <th>净度</th>
          <th>色泽</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>造型茶</td>
          <td>针形、兰花形成其他特殊造型</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>黄褐润</td>
          <td>鲜灵浓郁持久</td>
          <td>鲜浓醇厚</td>
          <td>嫩黄清澈明亮</td>
          <td>嫩黄绿明亮</td>
        </tr>
        <tr>
          <td>大白毫</td>
          <td>肥壮紧直重实满披白毫</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>黄褐银润</td>
          <td>鲜灵浓郁持久幽长</td>
          <td>鲜爽醇厚甘滑</td>
          <td>浅黄或杏黄鲜艳明亮</td>
          <td>肥嫩多芽嫩黄绿匀亮</td>
        </tr>
        <tr>
          <td>毛尖</td>
          <td>毫芽细秀紧结平伏白毫显露</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>黄褐油润</td>
          <td>鲜灵浓郁持久清幽</td>
          <td>鲜爽甘醇</td>
          <td>浅黄或杏黄清澈明亮</td>
          <td>细嫩显芽嫩黄绿匀亮</td>
        </tr>
        <tr>
          <td>毛峰</td>
          <td>紧结肥壮锋毫显露</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>黄褐润</td>
          <td>鲜灵浓郁高长</td>
          <td>鲜爽浓醇</td>
          <td>浅黄或杏黄清澈明亮</td>
          <td>肥嫩显芽嫩绿匀亮</td>
        </tr>
        <tr>
          <td>银毫</td>
          <td>紧结肥壮平伏毫芽显露</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>黄褐油润</td>
          <td>鲜灵浓郁</td>
          <td>鲜爽醇厚</td>
          <td>浅黄或黄清澈明亮</td>
          <td>肥嫩黄绿匀亮</td>
        </tr>
        <tr>
          <td>春毫</td>
          <td>紧结细嫩平伏毫芽较显</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>黄褐润</td>
          <td>鲜灵浓纯</td>
          <td>鲜爽醇厚</td>
          <td>黄明亮</td>
          <td>嫩匀黄绿匀亮</td>
        </tr>
        <tr>
          <td>香毫</td>
          <td>紧结显毫</td>
          <td>匀整</td>
          <td>净</td>
          <td>黄润</td>
          <td>鲜灵纯正</td>
          <td>鲜浓醇</td>
          <td>黄明亮</td>
          <td>嫩匀黄绿明亮</td>
        </tr>
      </table>
      <!-- 级别(烘青茉莉花茶） -->
      <table>
        <tr>
          <th rowspan="8">茉莉绿片</th>
          <th rowspan="2">级别(烘青茉莉花茶）</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>整碎</th>
          <th>净度</th>
          <th>色泽</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>细紧或肥壮有锋苗有毫</td>
          <td>匀整</td>
          <td>净</td>
          <td>绿黄润</td>
          <td>鲜浓持久</td>
          <td>浓醇爽</td>
          <td>黄亮</td>
          <td>嫩软匀齐黄绿明亮</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>紧结有锋苗</td>
          <td>匀整</td>
          <td>尚净</td>
          <td>绿黄尚润</td>
          <td>鲜浓</td>
          <td>浓醇</td>
          <td>黄明</td>
          <td>嫩匀黄绿明亮</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>尚紧结</td>
          <td>尚匀整</td>
          <td>稍有嫩茎</td>
          <td>绿黄</td>
          <td>尚鲜浓</td>
          <td>尚浓醇</td>
          <td>黄尚亮</td>
          <td>嫩尚匀，黄绿亮</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>尚紧结</td>
          <td>尚匀整</td>
          <td>有嫩茎</td>
          <td>尚绿黄</td>
          <td>尚浓</td>
          <td>醇和</td>
          <td>黄尚亮</td>
          <td>尚嫩匀黄绿</td>
        </tr>
        <tr>
          <td>四级</td>
          <td>稍松</td>
          <td>尚匀</td>
          <td>有茎梗</td>
          <td>黄稍暗</td>
          <td>香薄</td>
          <td>尚醇和</td>
          <td>黄欠亮</td>
          <td>稍有摊张绿黄</td>
        </tr>
        <tr>
          <td>五级</td>
          <td>稍粗松</td>
          <td>尚匀</td>
          <td>有梗朴</td>
          <td>黄稍枯</td>
          <td>香弱</td>
          <td>稍粗</td>
          <td>黄较暗</td>
          <td>稍粗大黄稍暗</td>
        </tr>
      </table>

      <table>
        <tr>
          <th rowspan="11">茉莉绿片</th>
          <th rowspan="2">级别(炒青茉莉花茶）</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
          <th rowspan="2">水分/%（质量分数）≤</th>
          <th rowspan="2">总灰分/%（质量分数）≤</th>
          <th rowspan="2">水浸出物/%（质量分数）≥</th>
          <th rowspan="2">粉末/%（质量分数）≤</th>
          <th rowspan="2">茉莉花干/%（质量分数）≤</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>整碎</th>
          <th>净度</th>
          <th>色泽</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特种</td>
          <td>扁平、卷曲、圆珠或其他特殊造型</td>
          <td>匀整</td>
          <td>净</td>
          <td>黄绿或黄褐润</td>
          <td>鲜灵浓郁持久</td>
          <td>鲜浓醇爽</td>
          <td>浅黄或黄明亮</td>
          <td>细嫩或肥嫩匀黄绿明亮</td>
          <td>8.5</td>
          <td>6.5</td>
          <td>34</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>特级</td>
          <td>紧结显锋苗</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>绿黄润</td>
          <td>鲜浓纯</td>
          <td>浓醇</td>
          <td>黄亮</td>
          <td>嫩匀黄绿明亮</td>
          <td>8.5</td>
          <td>6.5</td>
          <td>34</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>紧结</td>
          <td>匀整</td>
          <td>净</td>
          <td>绿黄尚润</td>
          <td>浓尚鲜</td>
          <td>浓尚醇</td>
          <td>黄明</td>
          <td>尚嫩匀黄绿尚亮</td>
          <td>8.5</td>
          <td>6.5</td>
          <td>34</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>紧实</td>
          <td>匀整</td>
          <td>稍有嫩茎</td>
          <td>绿黄</td>
          <td>浓尚鲜</td>
          <td>尚浓醇</td>
          <td>黄尚亮</td>
          <td>尚匀黄绿</td>
          <td>8.5</td>
          <td>6.5</td>
          <td>34</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>尚紧实</td>
          <td>尚匀整</td>
          <td>有筋梗</td>
          <td>尚绿黄</td>
          <td>尚浓</td>
          <td>尚浓</td>
          <td>黄尚亮</td>
          <td>欠匀绿黄</td>
          <td>8.5</td>
          <td>6.5</td>
          <td>32</td>
          <td>1.2</td>
          <td>1.5</td>
        </tr>
        <tr>
          <td>四级</td>
          <td>粗实</td>
          <td>尚匀整</td>
          <td>带梗朴</td>
          <td>黄稍暗</td>
          <td>香弱</td>
          <td>平和</td>
          <td>黄欠亮</td>
          <td>稍有摊张黄</td>
          <td>8.5</td>
          <td>6.5</td>
          <td>32</td>
          <td>1.2</td>
          <td>1.5</td>
        </tr>
        <tr>
          <td>五级</td>
          <td>稍粗实</td>
          <td>尚匀</td>
          <td>多梗朴</td>
          <td>黄稍枯</td>
          <td>香浮</td>
          <td>稍粗</td>
          <td>黄较暗</td>
          <td>稍粗黄稍暗</td>
          <td>8.5</td>
          <td>6.5</td>
          <td>32</td>
          <td>1.2</td>
          <td>1.5</td>
        </tr>
        <tr>
          <td>碎茶</td>
          <td colspan="8">
            通过紧门筛（筛网孔径0.8mm~1.6mm）洁净重实的颗粒茶，有花香，滋味尚醇
          </td>
          <td>8.5</td>
          <td>7</td>
          <td>32</td>
          <td>3</td>
          <td>1.5</td>
        </tr>
        <tr>
          <td>片茶</td>
          <td colspan="8">
            通过紧门筛（筛网孔径0.8mm~1.7mm）轻质片状茶，有花香，滋味尚纯
          </td>
          <td>8.5</td>
          <td>7</td>
          <td>32</td>
          <td>7</td>
          <td>1.5</td>
        </tr>
      </table>

      <!-- 表格数据294-319 -->
      <p>普洱方茶</p>
      <!-- 普洱方茶 -->
      <table>
        <tr>
          <th rowspan="8">普洱方茶</th>
          <th rowspan="2">级别(晒青茶）</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>色泽</th>
          <th>整碎</th>
          <th>净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>肥嫩紧结芽毫显</td>
          <td>绿润</td>
          <td>匀整</td>
          <td>稍有嫩茎</td>
          <td>清香浓郁</td>
          <td>浓醇回甘</td>
          <td>黄绿清净</td>
          <td>柔嫩显芽</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>肥壮紧结显毫</td>
          <td>绿润</td>
          <td>匀整</td>
          <td>有嫩茎</td>
          <td>清香尚浓</td>
          <td>浓厚</td>
          <td>黄绿明亮</td>
          <td>嫩匀</td>
        </tr>
        <tr>
          <td>四级</td>
          <td>紧结</td>
          <td>墨绿润泽</td>
          <td>尚匀整</td>
          <td>稍有梗片</td>
          <td>清香</td>
          <td>醇厚</td>
          <td>绿黄</td>
          <td>肥厚</td>
        </tr>
        <tr>
          <td>六级</td>
          <td>紧实</td>
          <td>深绿</td>
          <td>尚匀整</td>
          <td>有梗片</td>
          <td>纯正</td>
          <td>醇和</td>
          <td>绿黄</td>
          <td>肥壮</td>
        </tr>
        <tr>
          <td>八级</td>
          <td>粗实</td>
          <td>黄绿</td>
          <td>尚匀整</td>
          <td>梗片稍多</td>
          <td>平和</td>
          <td>平和</td>
          <td>绿黄稍浊</td>
          <td>肥壮</td>
        </tr>
        <tr>
          <td>十级</td>
          <td>粗松</td>
          <td>黄褐</td>
          <td>欠匀整</td>
          <td>梗片较多</td>
          <td>粗老</td>
          <td>粗淡</td>
          <td>黄浊</td>
          <td>粗老</td>
        </tr>
      </table>
      <!-- 级别(普洱茶（热茶）散茶） -->
      <table>
        <tr>
          <th rowspan="8">普洱方茶</th>
          <th rowspan="2">级别(普洱茶（热茶）散茶）</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>色泽</th>
          <th>整碎</th>
          <th>净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特级</td>
          <td>紧细</td>
          <td>红褐润显毫</td>
          <td>匀整</td>
          <td>匀净</td>
          <td>陈香浓郁</td>
          <td>浓醇甘爽</td>
          <td>红艳明亮</td>
          <td>红褐柔嫩</td>
        </tr>
        <tr>
          <td>一级</td>
          <td>紧结</td>
          <td>红褐润较显毫</td>
          <td>匀整</td>
          <td>匀净</td>
          <td>陈香浓厚</td>
          <td>浓醇回甘</td>
          <td>红浓明亮</td>
          <td>红褐较嫩</td>
        </tr>
        <tr>
          <td>三级</td>
          <td>尚紧结</td>
          <td>褐润较显毫</td>
          <td>匀整</td>
          <td>匀净带嫩梗</td>
          <td>陈香浓纯</td>
          <td>醇厚回甘</td>
          <td>红浓明亮</td>
          <td>红褐尚嫩</td>
        </tr>
        <tr>
          <td>五级</td>
          <td>紧实</td>
          <td>褐尚润</td>
          <td>匀齐</td>
          <td>尚匀稍带梗</td>
          <td>陈香尚浓</td>
          <td>浓厚回甘</td>
          <td>深红明亮</td>
          <td>红褐欠嫩</td>
        </tr>
        <tr>
          <td>七级</td>
          <td>尚紧实</td>
          <td>褐欠润</td>
          <td>尚匀齐</td>
          <td>尚匀带梗</td>
          <td>陈香纯正</td>
          <td>醇和回甘</td>
          <td>褐红尚浓</td>
          <td>红褐粗实</td>
        </tr>
        <tr>
          <td>九级</td>
          <td>粗松</td>
          <td>褐稍花</td>
          <td>欠匀齐</td>
          <td>欠匀带梗片</td>
          <td>陈香平和</td>
          <td>纯正回甘</td>
          <td>褐红尚浓</td>
          <td>红褐粗松</td>
        </tr>
      </table>

      <p>沱茶</p>
      <!-- 沱茶 -->
      <table>
        <tr>
          <th rowspan="2">沱茶</th>
          <th colspan="3">外形</th>
          <th colspan="4">内质</th>
        </tr>
        <tr>
          <th>条索</th>
          <th>色泽</th>
          <th>霉菌</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>碗臼形</td>
          <td>紧实、光滑</td>
          <td>墨绿、白毫显露</td>
          <td>无黑霉、白霉、青霉</td>
          <td>纯浓</td>
          <td>浓醇</td>
          <td>橙黄尚明</td>
          <td>嫩匀尚亮</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>茶梗（质量分数）%≤</th>
          <th>非茶类夹杂物（质量分数）%≤</th>
          <th>水浸出物（质量分数） ≥</th>
        </tr>
        <tr>
          <td>9</td>
          <td>7</td>
          <td>3</td>
          <td>0.2</td>
          <td>36</td>
        </tr>
      </table>
      <p>超微绿茶粉</p>
      <!-- 超微绿茶粉 -->
      <table>
        <tr>
          <th rowspan="2">超微绿茶粉</th>
          <th colspan="2">外形</th>
          <th colspan="3">内质</th>
          <th rowspan="2" style="width: 470px">制成茶坯的鲜叶原料</th>
        </tr>
        <tr>
          <th>色泽</th>
          <th>颗粒</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤质</th>
        </tr>
        <tr>
          <td>精品抹茶</td>
          <td>翠绿鲜活</td>
          <td>细腻均匀</td>
          <td>海苔香</td>
          <td>清鲜</td>
          <td>均匀</td>
          <td>
            4月中下旬开采前用遮阳率在85%以上遮阳网覆盖12~15天，中小叶茶树品种、当年生、一芽2、3叶和同等嫩度对夹叶
          </td>
        </tr>
        <tr>
          <td>玉品抹茶</td>
          <td>翠绿鲜活</td>
          <td>细腻均匀</td>
          <td>清香</td>
          <td>鲜爽</td>
          <td>均匀</td>
          <td>
            4月下旬开采前用遮阳率在75%以上遮阳网覆盖12~15天，中小叶茶树品种、当年生、一芽3、4叶和同等嫩度对夹叶、嫩单叶。
          </td>
        </tr>
        <tr>
          <td>一级</td>
          <td>嫩绿鲜活</td>
          <td>细腻均匀</td>
          <td>醇香</td>
          <td>清爽</td>
          <td>均匀</td>
          <td>一芽2/3叶为主的鲜叶</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>嫩绿鲜活</td>
          <td>细腻均匀</td>
          <td>醇香</td>
          <td>鲜醇</td>
          <td>均匀</td>
          <td>一芽2/3叶与同等嫩度的对夹片、嫩单片</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>粗纤维，g/100g≤</th>
          <th>粒度</th>
          <th>水浸出物（质量分数） ≥</th>
        </tr>
        <tr>
          <td>6</td>
          <td>7</td>
          <td>16</td>
          <td>粒径小于75微米的茶粉≥90%</td>
          <td>36</td>
        </tr>
      </table>
      <p>抹茶</p>
      <!-- 抹茶 -->
      <table>
        <tr>
          <th rowspan="2">抹茶</th>
          <th colspan="2">外形</th>
          <th colspan="3">内质</th>
        </tr>
        <tr>
          <th>色泽</th>
          <th>颗粒</th>
          <th>香气</th>
          <th>汤色</th>
          <th>滋味</th>
        </tr>
        <tr>
          <td>一级</td>
          <td>鲜绿明亮</td>
          <td>柔软细腻均匀</td>
          <td>覆盖香显著</td>
          <td>浓绿</td>
          <td>鲜纯味浓</td>
        </tr>
        <tr>
          <td>二级</td>
          <td>翠绿明亮</td>
          <td>细腻均匀</td>
          <td>覆盖香明显</td>
          <td>绿</td>
          <td>纯正味浓</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>粒度（D60）≤</th>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>茶氨酸总量（质量分数）%≥</th>
        </tr>
        <tr>
          <td>18微米</td>
          <td>6</td>
          <td>8</td>
          <td>1</td>
        </tr>
        <tr>
          <td>18微米</td>
          <td>6</td>
          <td>8</td>
          <td>1.5</td>
        </tr>
      </table>
      <p>绿茶浓缩茶汁</p>
      <!-- 绿茶浓缩茶汁 -->
      <table>
        <tr>
          <th rowspan="2">绿茶浓缩茶汁</th>
          <th>色泽</th>
          <th>滋味气味</th>
          <th>性状</th>
          <th>杂质</th>
        </tr>
        <tr>
          <td>黄绿色或浅黄色粉末</td>
          <td>具有绿色浓缩粉的滋味气味，不得有异味</td>
          <td>均匀固体粉末状</td>
          <td>肉眼可见的外来杂质</td>
        </tr>
      </table>
      <table>
        <tr>
          <th rowspan="2">茶多酚%</th>
          <th rowspan="2">儿茶素类%</th>
          <th colspan="2">咖啡碱%≤</th>
          <th rowspan="2">水分%≤</th>
          <th rowspan="2">总灰分%≤</th>
          <th rowspan="2">铅Pb（mg/kg）≤</th>
          <th rowspan="2">菌落总数（CFU/g）≤</th>
          <th rowspan="2">大肠杆菌（MPN/100g）≤</th>
          <th rowspan="2">霉菌及酵母（CFU/g）≤</th>
        </tr>
        <tr>
          <td>普通型</td>
          <td>低咖啡型</td>
        </tr>
        <tr>
          <td>25</td>
          <td>12</td>
          <td>12</td>
          <td>3</td>
          <td>6</td>
          <td>15</td>
          <td>1</td>
          <td>1000</td>
          <td>30</td>
          <td>100</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
p {
  font-size: 16px;
  margin: 20px;
  font-weight: bold;
  color: rgb(31, 83, 38);
  text-align: left;
}
table {
  border: 1px;

  border-collapse: collapse;
  width: 950px;
  text-align: center;
  margin-bottom: 20px;
}
table,
table tr th,
table tr td {
  border: 1px solid #050505;
}
</style>
