<template>
  <div style="overflow: scroll; height: 750px">
    <p>大红袍</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/大红袍.jpg" />
      <img src="../../assets/teaStandImg/wulong/大红袍2.jpg" />
      <img src="../../assets/teaStandImg/wulong/大红袍3.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="5">大红袍</th>
          <th rowspan="2">等级</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>

        <tr>
          <td>条索</td>
          <td>色泽</td>
          <td>整碎</td>
          <td>净度</td>
          <td>香气</td>
          <td>滋味</td>
          <td>汤色</td>
          <td>叶底</td>
        </tr>

        <tr>
          <td>特级</td>
          <td>紧结、壮实、稍扭曲</td>
          <td>褐红色至深褐色油润</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>锐、浓长或幽</td>
          <td>岩韵明显、醇厚、回味甘爽、杯底有余香</td>
          <td>清澈、艳丽、呈深橙黄色</td>
          <td>叶底软亮匀齐，红边或带朱砂色</td>
        </tr>

        <tr>
          <td>一级</td>
          <td>紧结、壮实</td>
          <td>褐红色、砂粒明</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>清远 浓长或幽</td>
          <td>岩韵明、醇厚、回甘快、杯底有余香</td>
          <td>较清澈、艳丽、呈深橙黄色</td>
          <td>叶底较软亮匀齐，红边或带朱砂色</td>
        </tr>

        <tr>
          <td>二级</td>
          <td>紧结、较壮实</td>
          <td>红点明显</td>
          <td>较匀整</td>
          <td>洁净</td>
          <td>清远 幽长</td>
          <td>岩韵明、较醇厚、回甘、杯底有余香</td>
          <td>橙黄清澈、明亮</td>
          <td>较软亮、较匀齐、红边较显</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分（质量分数）/（%） ≤</th>
          <th>总灰分（质量分数）/（%）≤</th>
          <th>碎茶（质量分数）/（%）≤</th>
          <th>粉末（质量分数）/（%）≤</th>
        </tr>
        <tr>
          <td>7</td>
          <td>7</td>
          <td>16</td>
          <td>15</td>
        </tr>
      </table>
    </div>
    <p>袋泡青茶</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/袋泡青茶1.jpg" />
      <img src="../../assets/teaStandImg/wulong/袋泡青茶2.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="3">袋泡青茶</th>
          <th colspan="3" style="width: 700px">内质</th>
          <th rowspan="2">水分（质量分数）/（%） ≤</th>
          <th rowspan="2">总灰分（质量分数）/（%）≤</th>
          <th rowspan="2">水浸出物（质量分数）/（%）≤</th>
        </tr>

        <tr>
          <td>香气</td>
          <td>滋味</td>
          <td>汤色</td>
        </tr>

        <tr>
          <td>纯正</td>
          <td>醇和</td>
          <td>橙黄或黄绿</td>
          <td>7.5</td>
          <td>7.5</td>
          <td>30</td>
        </tr>
      </table>
    </div>
    <p>单丛奇种</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/单丛奇种.jpg" />
      <img src="../../assets/teaStandImg/wulong/单丛奇种2.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="6">单丛奇种</th>
          <th rowspan="2">等级</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
        </tr>

        <tr>
          <td>条索</td>
          <td>色泽</td>
          <td>整碎</td>
          <td>净度</td>
          <td>香气</td>
          <td>滋味</td>
          <td>汤色</td>
          <td>叶底</td>
        </tr>

        <tr>
          <td>特级</td>
          <td>紧结重实</td>
          <td>褐润</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>花蜜香清高悠长</td>
          <td>甜醇回苷、高山韵显</td>
          <td>金黄明亮</td>
          <td>肥厚软亮、匀整</td>
        </tr>

        <tr>
          <td>一级</td>
          <td>较紧结重实</td>
          <td>较褐润</td>
          <td>较匀整</td>
          <td>匀净</td>
          <td>花蜜香持久</td>
          <td>依醇回甘、蜜韵显</td>
          <td>金黄尚亮</td>
          <td>较肥厚软亮、较匀整</td>
        </tr>

        <tr>
          <td>二级</td>
          <td>稍紧结重实</td>
          <td>稍褐润</td>
          <td>尚匀整</td>
          <td>尚匀有细梗</td>
          <td>花蜜香纯正</td>
          <td>尚醇厚、蜜韵较显</td>
          <td>深金黄</td>
          <td>尚软亮</td>
        </tr>

        <tr>
          <td>三级</td>
          <td>稍紧结</td>
          <td>褐欠润</td>
          <td>尚匀</td>
          <td>有梗片</td>
          <td>蜜香显</td>
          <td>尚醇稍厚</td>
          <td>深金黄、稍暗</td>
          <td>稍软欠亮</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分（质量分数）/（%） ≤</th>
          <th>总灰分（质量分数）/（%） ≤</th>
          <th>碎茶（质量分数）/（%） ≤</th>
          <th>水浸出物（质量分数）/% ≥</th>
          <th>粉末（质量分数）/（%） ≤</th>
        </tr>
        <tr>
          <td>7</td>
          <td>6.5</td>
          <td>16</td>
          <td>32</td>
          <td>1.3</td>
        </tr>
      </table>
    </div>
    <p>凤凰单丛</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/凤凰单丛.jpg" />
      <img src="../../assets/teaStandImg/wulong/凤凰单丛2.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="6">凤凰单丛</th>
          <th rowspan="2">等级</th>
          <th rowspan="2">外形</th>
          <th colspan="4">内质</th>
        </tr>

        <tr>
          <td>香气</td>
          <td>滋味</td>
          <td>汤色</td>
          <td>叶底</td>
        </tr>

        <tr>
          <td>特级</td>
          <td>紧结壮直，匀整，褐润有光</td>
          <td>天然花香、清高细锐、持久</td>
          <td>鲜爽回甘，有鲜明花香浓醇爽口，有明显花香，特殊韵味</td>
          <td>金黄清澈明亮</td>
          <td>淡黄红边，软柔鲜亮</td>
        </tr>

        <tr>
          <td>一级</td>
          <td>紧结壮直，匀整，褐润</td>
          <td>清高花香、持久</td>
          <td>浓醇爽口，有明显花香味，有韵味</td>
          <td>金黄清澈</td>
          <td>淡黄，软柔，明亮</td>
        </tr>

        <tr>
          <td>二级</td>
          <td>尚紧结，匀齐，尚润</td>
          <td>清香尚长</td>
          <td>醉厚尚爽，有花香味</td>
          <td>清黄</td>
          <td>淡黄，尚软，尚亮</td>
        </tr>

        <tr>
          <td>三级</td>
          <td>尚紧结，匀净，乌褐</td>
          <td>清香</td>
          <td>浓醇，稍有花香</td>
          <td>棕黄</td>
          <td>尚软，尚亮</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分（质量分数）/（%） ≤</th>
          <th>水浸出物（质量分数）/%≥</th>
          <th>粉末（质量分数）/（%） ≤</th>
          <th>粗纤维（质量分数）/（%） ≤</th>
          <th>茶多酚（质量分数）/（%</th>
        </tr>
        <tr>
          <td>6</td>
          <td>30</td>
          <td>1.5</td>
          <td>16</td>
          <td>16</td>
        </tr>
      </table>
    </div>
    <p>桂花花茶</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/桂花花茶1.jpg" />
      <img src="../../assets/teaStandImg/wulong/桂花花茶2.jpg" />
      <img src="../../assets/teaStandImg/wulong/桂花花茶3.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="5">桂花花茶</th>
          <th>色泽</th>
          <th>具有该品种产品固有的色泽，色泽均匀</th>
          <th>水分/ (%)≤</th>
          <th>总灰分/ (%)≤</th>
        </tr>

        <tr>
          <td>气味和滋味</td>
          <td>具有茶叶、花、果等天然物料固有的色泽和香味，无异味</td>
          <td rowspan="4">13</td>
          <td rowspan="4">10</td>
        </tr>

        <tr>
          <td>组织形态</td>
          <td>具有该品种产品的自然品质特征，无霉变</td>
        </tr>

        <tr>
          <td>汤色</td>
          <td>
            冲泡后桂花茶呈淡黄色，玫瑰花茶呈浅黄褐色，味甘甜清香，醇和回甘
          </td>
        </tr>

        <tr>
          <td>杂质</td>
          <td>无肉眼可见的外来杂质</td>
        </tr>
      </table>
    </div>
    <p>黄金桂</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/黄金桂.jpg" />
      <img src="../../assets/teaStandImg/wulong/黄金桂2.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="4">黄金桂</th>
          <th rowspan="2">等级</th>
          <th colspan="4" style="width: 450px">外形</th>
          <th colspan="4" style="width: 450px">内质</th>
          <th rowspan="2">水分（质量分数）/（%） ≤</th>
          <th rowspan="2">>总灰分（质量分数）/（%） ≤</th>
          <th rowspan="2">>碎茶（质量分数）/（%） ≤</th>
          <th rowspan="2">>水浸出物（质量分数）/%≥</th>
          <th rowspan="2">>粉末（质量分数）/（%） ≤</th>
        </tr>

        <tr>
          <td>条索</td>
          <td>整碎</td>
          <td>净度</td>
          <td>色泽</td>
          <td>香气</td>
          <td>滋味</td>
          <td>汤色</td>
          <td>叶底</td>
        </tr>

        <tr>
          <td>特级</td>
          <td>紧结</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>黄绿有光泽</td>
          <td>花香清高持久</td>
          <td>清醇鲜爽、品种特征显</td>
          <td>金黄明亮</td>
          <td>软亮、有余香</td>
          <td rowspan="2">7.5</td>
          <td rowspan="2">6.5</td>
          <td rowspan="2">16</td>
          <td rowspan="2">32</td>
          <td rowspan="2">1.3</td>
        </tr>

        <tr>
          <td>一级</td>
          <td>紧实</td>
          <td>尚匀整</td>
          <td>尚洁净</td>
          <td>尚黄绿</td>
          <td>花香尚清高持久</td>
          <td>醇、品种特征显</td>
          <td>清黄</td>
          <td>尚软亮、匀整</td>
        </tr>
      </table>
    </div>
    <p>龙图香茶</p>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="4">龙图香茶</th>
          <th>等级</th>
          <th>质量要求</th>
        </tr>

        <tr>
          <td>一级</td>
          <td>一芽一、二叶70%以上，芽叶匀整。</td>
        </tr>

        <tr>
          <td>二级</td>
          <td>一芽二、三叶60%以上，芽叶较完整，匀净。</td>
        </tr>

        <tr>
          <td>三级</td>
          <td>一芽四叶50%以上，可含少量同等嫩度的对夹叶或单片。</td>
        </tr>
      </table>
    </div>
    <p>闽北乌龙、台湾乌龙</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/闽北乌龙.jpg" />
      <img src="../../assets/teaStandImg/wulong/闽北乌龙2.jpg" />
      <img src="../../assets/teaStandImg/wulong/台湾乌龙.jpg" />
      <img src="../../assets/teaStandImg/wulong/台湾乌龙2.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="5">闽北乌龙、台湾乌龙</th>
          <td>水分（质量分数）/（%） ≤</td>
          <td>7</td>
        </tr>

        <tr>
          <td>总灰分（质量分数）/（%） ≤</td>
          <td>6.5</td>
        </tr>

        <tr>
          <td>碎茶（质量分数）/（%） ≤</td>
          <td>16</td>
        </tr>

        <tr>
          <td>水浸出物（质量分数）/（%） ≥</td>
          <td>32</td>
        </tr>

        <tr>
          <td>粉末（质量分数）/（%） ≤</td>
          <td>1.3</td>
        </tr>
      </table>
    </div>
    <p>名茶奇种</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/奇种.jpg" />
      <img src="../../assets/teaStandImg/wulong/奇种2.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="6">名茶奇种</th>
          <th rowspan="2">等级</th>
          <th colspan="4" style="width: 400px">外形</th>
          <th colspan="4" style="width: 400px">内质</th>
          <th rowspan="2">水分（质量分数）/（%） ≤</th>
          <th rowspan="2">总灰分（质量分数）/（%） ≤</th>
          <th rowspan="2">碎茶（质量分数）/（%） ≤</th>
          <th rowspan="2">粉末（质量分数）/（%） ≤</th>
        </tr>

        <tr>
          <td>条索</td>
          <td>色泽</td>
          <td>整碎</td>
          <td>净度</td>
          <td>香气</td>
          <td>滋味</td>
          <td>汤色</td>
          <td>叶底</td>
        </tr>

        <tr>
          <td>特级</td>
          <td>紧结、重实</td>
          <td>翠润</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>清高</td>
          <td>清醇甘爽岩韵显</td>
          <td>金黄清澈</td>
          <td>软亮匀齐、红边鲜艳</td>
          <td rowspan="4">6.5</td>
          <td rowspan="4">6.5</td>
          <td rowspan="4">15</td>
          <td rowspan="4">1.3</td>
        </tr>

        <tr>
          <td>一级</td>
          <td>结实</td>
          <td>油润</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>清纯</td>
          <td>尚醇厚、岩韵明</td>
          <td>较金黄清澈</td>
          <td>软无较匀齐、红边明显</td>
        </tr>

        <tr>
          <td>二级</td>
          <td>尚结实</td>
          <td>尚油润</td>
          <td>较匀整</td>
          <td>较洁净</td>
          <td>尚浓</td>
          <td>尚醇正</td>
          <td>金黄稍深</td>
          <td>尚软亮习整</td>
        </tr>

        <tr>
          <td>三级</td>
          <td>尚壮实</td>
          <td>尚润</td>
          <td>尚匀整</td>
          <td>尚洁净</td>
          <td>平正</td>
          <td>欠醇</td>
          <td>橙黄稍深</td>
          <td>欠匀稍亮</td>
        </tr>
      </table>
    </div>
    <p>青龙井</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/青龙井1.jpg" />
      <img src="../../assets/teaStandImg/wulong/青龙井2.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="8">青龙井</th>
          <th rowspan="2">等级</th>
          <th rowspan="2">外形</th>
          <th colspan="4">内质</th>
          <th rowspan="2">水分（质量分数）/（%） ≤</th>
          <th rowspan="2">总灰分（质量分数）/（%） ≤</th>
          <th rowspan="2">碎茶（质量分数）/（%） ≤</th>
          <th rowspan="2">粉末（质量分数）/（%） ≤</th>
          <th rowspan="2">水浸出物（质量分数）/%≥</th>
        </tr>

        <tr>
          <td>香气</td>
          <td>滋味</td>
          <td>汤色</td>
          <td>叶底</td>
        </tr>

        <tr>
          <td>特级</td>
          <td>扁平光润、挺直尖削;嫩绿鲜润;匀整重实;匀净</td>
          <td>清香持久</td>
          <td>鲜醇甘爽</td>
          <td>嫩绿明亮、清澈</td>
          <td>芽叶细嫩成朵,匀齐，嫩绿明亮</td>
          <td rowspan="3">6.5</td>
          <td rowspan="3">6.5</td>
          <td rowspan="6">1</td>
          <td rowspan="6">1</td>
          <td rowspan="6">36</td>
        </tr>

        <tr>
          <td>一级</td>
          <td>扁平光滑尚润、挺直;嫩绿尚鲜润;匀绿尚鲜润;匀整有锋;洁净</td>
          <td>清香尚持久</td>
          <td>鲜醇爽口</td>
          <td>嫩绿明亮</td>
          <td>细嫩成朵，嫩绿明亮</td>
        </tr>

        <tr>
          <td>二级</td>
          <td>扁平挺直，尚光滑;绿润;匀整;尚洁净</td>
          <td>清香</td>
          <td>尚鲜</td>
          <td>绿明亮</td>
          <td>尚细嫩成朵，绿明亮</td>
        </tr>

        <tr>
          <td>三级</td>
          <td>扁平、尚光滑，尚挺直;尚绿润;尚匀|深;尚匀; 稍整;尚洁净</td>
          <td>尚清香</td>
          <td>尚醇</td>
          <td>尚绿明亮</td>
          <td>尚成朵,有嫩单片,浅绿尚明亮</td>
          <td rowspan="3">7</td>
          <td rowspan="3">7</td>
        </tr>

        <tr>
          <td>四级</td>
          <td>扁平，稍有宽扁条;绿稍扁条;绿稍有青黄片</td>
          <td>纯正</td>
          <td>尚醇</td>
          <td>绿黄绿明亮明亮</td>
          <td>尚嫩匀稍有青张.尚绿明</td>
        </tr>

        <tr>
          <td>五级</td>
          <td>尚扁平，有宽扁条;深绿较暗;尚整;有青壳碎片</td>
          <td>平和</td>
          <td>尚纯正</td>
          <td>黄绿</td>
          <td>尚嫩欠匀,稍有青张,绿稍深</td>
        </tr>
      </table>
    </div>
    <p>铁观音</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/铁观音.jpg" />
      <img src="../../assets/teaStandImg/wulong/铁观音2.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="8">铁观音</th>
          <th rowspan="2">等级</th>
          <th colspan="4">外形</th>
          <th colspan="4">内质</th>
          <th rowspan="2">水分（质量分数）/（%） ≤</th>
          <th rowspan="2">总灰分（质量分数）/（%） ≤</th>
          <th rowspan="2">碎茶（质量分数）/（%） ≤</th>
          <th rowspan="2">粉末（质量分数）/（%） ≤</th>
          <th rowspan="2">水浸出物（质量分数）/% ≥</th>
        </tr>

        <tr>
          <td>条索</td>
          <td>色泽</td>
          <td>整碎</td>
          <td>净度</td>
          <td>香气</td>
          <td>滋味</td>
          <td>汤色</td>
          <td>叶底</td>
        </tr>

        <tr>
          <td>特级</td>
          <td>紧结、重实</td>
          <td>翠绿润、砂绿明显</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>清高、持久</td>
          <td>晴醉鲜爽，音韵明显</td>
          <td>金黄带绿、清澈</td>
          <td>肥厚软亮、匀整</td>
          <td rowspan="5">7</td>
          <td rowspan="5">6.5</td>
          <td rowspan="5">16</td>
          <td rowspan="5">1.3</td>
          <td rowspan="5">32</td>
        </tr>

        <tr>
          <td>一级</td>
          <td>紧结</td>
          <td>绿油润、绿油润</td>
          <td>匀整</td>
          <td>净</td>
          <td>较清高、持久</td>
          <td>清醇较爽，音韵较显</td>
          <td>金黄带绿、明亮</td>
          <td>较软亮、尚匀整</td>
        </tr>

        <tr>
          <td>二级</td>
          <td>较紧结</td>
          <td>乌绿</td>
          <td>尚匀整</td>
          <td>尚净、稍有细嫩梗</td>
          <td>稍清高</td>
          <td>醇和、音韵尚明</td>
          <td>清黄</td>
          <td>稍软亮、尚匀整</td>
        </tr>

        <tr>
          <td>三级</td>
          <td>尚结实</td>
          <td>乌绿、稍带黄</td>
          <td>尚匀整</td>
          <td>尚净、稍有细嫩梗</td>
          <td>平正</td>
          <td>平和</td>
          <td>尚清黃</td>
          <td>尚匀整</td>
        </tr>
      </table>
    </div>
    <p>乌龙茶</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/乌龙.jpg" />
      <img src="../../assets/teaStandImg/wulong/乌龙2.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="6">乌龙茶</th>
          <th rowspan="2">等级</th>
          <th colspan="4" style="width: 400px">外形</th>
          <th colspan="4" style="width: 400px">内质</th>
          <th rowspan="2">水分/(g/100 g) ≤</th>
          <th rowspan="2">总灰分(以干物质计)/(g/100 g)≤</th>
          <th rowspan="2">碎茶（质量分数）/（%） ≤</th>
          <th rowspan="2">粉末（质量分数）/（%） ≤</th>
          <th rowspan="2">水浸出物（质量分数）/%≥</th>
        </tr>

        <tr>
          <td>条索</td>
          <td>色泽</td>
          <td>整碎</td>
          <td>净度</td>
          <td>香气</td>
          <td>滋味</td>
          <td>汤色</td>
          <td>叶底</td>
        </tr>

        <tr>
          <td>特级</td>
          <td>紧结重实</td>
          <td>墨绿油润</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>花香持久</td>
          <td>醇爽回甘</td>
          <td>蜜绿清澈明亮</td>
          <td>肥厚软亮</td>
          <td rowspan="4">7</td>
          <td rowspan="4">6.5</td>
          <td rowspan="4">16</td>
          <td rowspan="4">1.3</td>
          <td rowspan="4">32</td>
        </tr>

        <tr>
          <td>一级</td>
          <td>较紧结重实</td>
          <td>墨绿较润</td>
          <td>较匀整</td>
          <td>较洁净</td>
          <td>花香显</td>
          <td>醇爽</td>
          <td>蜜绿明亮</td>
          <td>软、匀亮</td>
        </tr>

        <tr>
          <td>二级</td>
          <td>尚紧结重实</td>
          <td>绿尚润</td>
          <td>尚匀整</td>
          <td>尚净,有细梗</td>
          <td>清香</td>
          <td>较醇</td>
          <td>黄绿明亮</td>
          <td>较软、尚匀</td>
        </tr>

        <tr>
          <td>三级</td>
          <td>尚紧结</td>
          <td>较绿</td>
          <td>尚匀</td>
          <td>有梗片</td>
          <td>带清香</td>
          <td>尚醇</td>
          <td>黄绿尚亮</td>
          <td>尚软</td>
        </tr>
      </table>
    </div>
    <p>岩茶水仙、闽北水仙、凤凰水仙</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/wulong/岩茶水仙.jpg" />
      <img src="../../assets/teaStandImg/wulong/岩茶水仙2.jpg" />
      <img src="../../assets/teaStandImg/wulong/闽北水仙.jpg" />
      <img src="../../assets/teaStandImg/wulong/凤凰水仙.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th rowspan="6">岩茶水仙、闽北水仙、凤凰水仙</th>
          <th rowspan="2">等级</th>
          <th colspan="4" style="width: 400px">外形</th>
          <th colspan="4" style="width: 400px">内质</th>
          <th rowspan="2">水分(质量分数)/% ≤</th>
          <th rowspan="2">总灰分(质量分数）/%≤</th>
          <th rowspan="2">碎茶（质量分数）/（%） ≤</th>
          <th rowspan="2">粉末（质量分数）/（%） ≤</th>
          <th rowspan="2">水浸出物（质量分数）/%≥</th>
        </tr>

        <tr>
          <td>条索</td>
          <td>色泽</td>
          <td>整碎</td>
          <td>净度</td>
          <td>香气</td>
          <td>滋味</td>
          <td>汤色</td>
          <td>叶底</td>
        </tr>

        <tr>
          <td>特级</td>
          <td>壮结</td>
          <td>乌油润浓</td>
          <td>匀整</td>
          <td>洁净</td>
          <td>浓郁或清长</td>
          <td>鲜醉浓爽或醇厚甘爽</td>
          <td>橙黄明亮</td>
          <td>肥厚软亮</td>
          <td rowspan="4">7</td>
          <td rowspan="4">6.5</td>
          <td rowspan="4">16</td>
          <td rowspan="4">1.3</td>
          <td rowspan="4">32</td>
        </tr>

        <tr>
          <td>一级</td>
          <td>较壮结</td>
          <td>较乌润</td>
          <td>匀整</td>
          <td>匀净</td>
          <td>清香</td>
          <td>较醇厚尚甘</td>
          <td>橙黄清澈</td>
          <td>尚肥厚软亮</td>
        </tr>

        <tr>
          <td>二级</td>
          <td>尚紧结</td>
          <td>尚油润</td>
          <td>尚匀整</td>
          <td>尚勾净</td>
          <td>清纯</td>
          <td>尚浓</td>
          <td>橙红</td>
          <td>尚软亮</td>
        </tr>

        <tr>
          <td>三级</td>
          <td>粗壮</td>
          <td>乌褐</td>
          <td>稍匀整</td>
          <td>带细梗轻片</td>
          <td>纯和</td>
          <td>稍淡</td>
          <td>深橙红稍暗</td>
          <td>欠亮</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
img {
  width: 200px;
  height: 200px;
  margin-left: 20px;
  margin-right: 20px;
}
.imgcontainer {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: center;
  width: 950px;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
}
.tablecontainer {
  margin-bottom: 80px;
}
p {
  text-align: left;
  font-size: 16px;
  margin: 20px;
  font-weight: bold;
  color: rgb(31, 83, 38);
}
table {
  border: 1px;

  border-collapse: collapse;
  width: 950px;
  text-align: center;
  margin-bottom: 20px;
}
table,
table tr th,
table tr td {
  border: 1px solid #050505;
}
</style>
