<template>
  <div style="overflow: scroll; height: 750px">
    <p>安茶（DB）</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/black/安茶-茶叶.jpg" />
      <img src="../../assets/teaStandImg/black/安茶-散茶.jpg" />
      <img src="../../assets/teaStandImg/black/安茶-汤色.jpg" />
      <img src="../../assets/teaStandImg/black/安茶-成品.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th>产品级别</th>
          <th>鲜叶要求</th>
          <th>产品级别</th>
          <th>形状</th>
          <th>色泽</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>
        <tr>
          <td>特贡</td>
          <td>一芽一叶、一芽二叶初展为主</td>
          <td>特贡</td>
          <td>条索紧细、匀整</td>
          <td>黑褐油润有毫</td>
          <td>高长</td>
          <td>醇甜</td>
          <td>澄黄明亮</td>
          <td>嫩匀、黄褐明亮</td>
        </tr>

        <tr>
          <td>贡尖</td>
          <td>一芽二叶为主</td>
          <td>贡尖</td>
          <td>条索紧结、匀整</td>
          <td>黑褐油润</td>
          <td>高纯</td>
          <td>醇爽</td>
          <td>澄黄明亮</td>
          <td>嫩匀、黄褐明亮</td>
        </tr>

        <tr>
          <td>毛尖</td>
          <td>一芽二叶、一芽三叶为主</td>
          <td>毛尖</td>
          <td>条索较紧结、较匀整</td>
          <td>黑褐尚润</td>
          <td>纯较高</td>
          <td>醇厚</td>
          <td>澄黄较亮</td>
          <td>尚嫩、黄褐较亮</td>
        </tr>

        <tr>
          <td>一级</td>
          <td>一芽三叶为主</td>
          <td>一级</td>
          <td>条索较紧、尚匀，有嫩茎梗</td>
          <td>黑褐</td>
          <td>纯尚高</td>
          <td>醇浓</td>
          <td>澄明</td>
          <td>较匀、黄褐</td>
        </tr>
      </table>

      <table>
        <tr>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>水溶性灰分占总灰分（质量分数） ≥</th>
          <th>水浸出物（质量分数） ≥</th>
          <th>粗纤维（质量分数） ≤</th>
        </tr>

        <tr>
          <td>13.0(计重水分 11.0％)</td>
          <td>8</td>
          <td>45</td>
          <td>34</td>
          <td>16</td>
        </tr>
      </table>
    </div>

    <p>茯砖茶</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/black/茯砖茶-成品.jpg" />
      <img src="../../assets/teaStandImg/black/茯砖茶-成品2.jpg" />
      <img src="../../assets/teaStandImg/black/茯砖茶-冲泡.jpg" />
      <img src="../../assets/teaStandImg/black/茯砖茶-茶水.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th>外形</th>
          <th>内质</th>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>茶梗（质量分数）%≤</th>
          <th>非茶类夹杂物（质量分数）%≤</th>
          <th>水浸出物（质量分数） ≥</th>
          <th>冠突散囊菌（CFU/g）≥</th>
        </tr>

        <tr>
          <td>
            砖面平整，棱角分明，厚薄一致，色泽黄褐色，发花普遍，砖内无黑霉、白霉、青霉、红霉等杂菌。
          </td>
          <td>香气纯正，汤色橙黄，滋味纯和，无涩味。</td>
          <td>14.0(计重水分 12.0％)</td>
          <td>9</td>
          <td>20.0（其中长于30mm的茶梗不得超过1.0%）</td>
          <td>0.2</td>
          <td>20</td>
          <td>20*10^4</td>
        </tr>
      </table>
    </div>

    <p>黑砖茶</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/black/黑砖茶.jpg" />
      <img src="../../assets/teaStandImg/black/黑砖茶-原料.jpg" />
      <img src="../../assets/teaStandImg/black/黑砖茶-成品.jpg" />
      <img src="../../assets/teaStandImg/black/黑砖茶-泡茶.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th>外形</th>
          <th>内质</th>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>茶梗（质量分数）%≤</th>
          <th>非茶类夹杂物（质量分数）%≤</th>
          <th>水浸出物（质量分数） ≥</th>
        </tr>

        <tr>
          <td>
            砖面平整，图案清晰，棱角分明，厚薄一致，色泽黑褐，无黑霉、白霉、青霉、等杂菌。
          </td>
          <td>香气纯正或带松烟香，汤色橙黄，滋味醇和，微涩。</td>
          <td>14.0(计重水分 12.0％)</td>
          <td>8.5</td>
          <td>18.0（其中长于30mm的茶梗不得超过1.0%）</td>
          <td>0.2</td>
          <td>21</td>
        </tr>
      </table>
    </div>

    <p>花砖茶</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/black/花砖茶.jpg" />
      <img src="../../assets/teaStandImg/black/花砖茶-汤色.jpg" />
      <img src="../../assets/teaStandImg/black/花砖茶-成品1.jpeg" />
      <img src="../../assets/teaStandImg/black/花砖茶-加工1.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th>外形</th>
          <th>内质</th>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>茶梗（质量分数）%≤</th>
          <th>非茶类夹杂物（质量分数）%≤</th>
          <th>水浸出物（质量分数） ≥</th>
        </tr>

        <tr>
          <td>
            砖面平整，花纹图案清晰，棱角分明，厚薄一致，色泽黑褐，无黑霉、白霉、青霉、等霉菌。
          </td>
          <td>香气纯正或带松烟香，汤色橙黄，滋味醇和。</td>
          <td>14.0(计重水分 12.0％)</td>
          <td>8</td>
          <td>15.0（其中长于30mm的茶梗不得超过1.0%）</td>
          <td>0.2</td>
          <td>22</td>
        </tr>
      </table>
    </div>

    <p>紧茶</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/black/紧茶-成品.jpg" />
      <img src="../../assets/teaStandImg/black/紧茶-成品1.jpg" />
      <img src="../../assets/teaStandImg/black/紧茶-汤色.jpg" />
      <img src="../../assets/teaStandImg/black/紧茶-汤色1.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th>外形</th>
          <th>内质</th>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>茶梗（质量分数）%≤</th>
          <th>非茶类夹杂物（质量分数）%≤</th>
          <th>水浸出物（质量分数） ≥</th>
        </tr>

        <tr>
          <td>
            长方形小砖块（或心脏形），表面紧实，厚薄均匀，色泽尚乌、有毫，砖内无黑霉、白霉、青霉、等霉菌。
          </td>
          <td>香气纯正，汤色橙红尚明，滋味浓厚，叶底尚嫩欠匀。</td>
          <td>13.0(计重水分 10.0％)</td>
          <td>7.5</td>
          <td>8.0（其中长于30mm的茶梗不得超过1.0%）</td>
          <td>0.2</td>
          <td>36</td>
        </tr>
      </table>
    </div>

    <p>康砖茶</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/black/康砖茶-成品.jpg" />
      <img src="../../assets/teaStandImg/black/康砖茶-成品1.jpg" />
      <img src="../../assets/teaStandImg/black/康砖茶-汤色.jpg" />
      <img src="../../assets/teaStandImg/black/康砖茶-汤色1.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th>级别</th>
          <th>外形</th>
          <th>内质</th>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>茶梗（质量分数）%≤</th>
          <th>非茶类夹杂物（质量分数）%≤</th>
          <th>水浸出物（质量分数） ≥</th>
        </tr>

        <tr>
          <td>特质康砖</td>
          <td>
            园角长方体，表面平整紧实，洒面明显，色泽棕褐油润，砖内无黑霉、白霉、青霉、等霉菌。
          </td>
          <td>香气纯正，陈香显，汤色红亮，滋味醇厚，叶底棕褐稍花杂、带细梗</td>
          <td>16.0(计重水分 14.0％)</td>
          <td>7.5</td>
          <td>7.0（其中长于30mm的茶梗不得超过1.0%）</td>
          <td>0.2</td>
          <td>28</td>
        </tr>

        <tr>
          <td>普通康砖</td>
          <td>
            园角长方体，表面尚平整，洒面尚明显，色泽棕褐，砖内无黑霉、白霉、青霉、等霉菌。
          </td>
          <td>香气较纯正，汤色红褐，尚明，滋味醇和，叶底棕褐花杂、带梗</td>
          <td>16.0(计重水分 14.0％)</td>
          <td>7.5</td>
          <td>8.0（其中长于30mm的茶梗不得超过1.0%</td>
          <td>0.2</td>
          <td>26</td>
        </tr>
      </table>
    </div>

    <p>六堡茶（散茶）</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/black/六堡茶-饼茶.jpg" />
      <img src="../../assets/teaStandImg/black/六堡茶-散茶.jpg" />
      <img src="../../assets/teaStandImg/black/六堡茶-散茶1.jpg" />
      <img src="../../assets/teaStandImg/black/六堡茶-汤色.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th>级别</th>
          <th>条索</th>
          <th>整碎</th>
          <th>色泽</th>
          <th>净度</th>
          <th>香气</th>
          <th>滋味</th>
          <th>汤色</th>
          <th>叶底</th>
        </tr>

        <tr>
          <td>特级</td>
          <td>紧细</td>
          <td>匀整</td>
          <td>黑褐、黑、油润</td>
          <td>净</td>
          <td>陈香纯正</td>
          <td>陈、醇厚</td>
          <td>明亮</td>
          <td>褐、黑褐、细嫩柔软、明亮</td>
        </tr>

        <tr>
          <td>一级</td>
          <td>紧结</td>
          <td>匀整</td>
          <td>黑褐、黑、油润</td>
          <td>净</td>
          <td>陈香纯正</td>
          <td>陈、尚醇厚</td>
          <td>深红、明亮</td>
          <td>褐、黑褐、尚细嫩柔软、明亮</td>
        </tr>

        <tr>
          <td>二级</td>
          <td>尚紧结</td>
          <td>较匀整</td>
          <td>黑褐、黑、尚油润</td>
          <td>净、稍含嫩茎</td>
          <td>陈香纯正</td>
          <td>陈、浓醇</td>
          <td>尚深红、明亮</td>
          <td>褐、黑褐、嫩柔软、明亮</td>
        </tr>

        <tr>
          <td>三级</td>
          <td>粗实、紧卷</td>
          <td>较匀整</td>
          <td>黑褐、黑、尚油润</td>
          <td>净、有嫩茎</td>
          <td>陈香纯正</td>
          <td>陈、尚浓醇</td>
          <td>红、明亮</td>
          <td>褐、黑褐、尚柔软、明亮</td>
        </tr>

        <tr>
          <td>四级</td>
          <td>粗实</td>
          <td>尚匀整</td>
          <td>黑褐、黑、尚油润</td>
          <td>净、有茎</td>
          <td>陈香纯正</td>
          <td>陈、醇正</td>
          <td>红、明亮</td>
          <td>褐、黑褐、稍硬、明亮</td>
        </tr>

        <tr>
          <td>五级</td>
          <td>粗松</td>
          <td>尚匀整</td>
          <td>黑褐、黑</td>
          <td>尚净、稍有筋梗茎梗</td>
          <td>陈香纯正</td>
          <td>陈、尚醇正</td>
          <td>尚红、尚明亮</td>
          <td>褐、黑褐、稍硬、明亮</td>
        </tr>

        <tr>
          <td>六级</td>
          <td>粗老</td>
          <td>尚匀</td>
          <td>黑褐、黑</td>
          <td>尚净、有筋梗茎梗</td>
          <td>陈香尚纯正</td>
          <td>陈、尚醇</td>
          <td>尚红、尚亮</td>
          <td>褐、黑褐、稍硬、尚亮</td>
        </tr>
      </table>
      <table>
        <tr>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>茶梗（质量分数）%≤</th>
          <th>非茶类夹杂物（质量分数）%≤</th>
          <th>水浸出物（质量分数） ≥</th>
        </tr>

        <tr>
          <td>12</td>
          <td>8</td>
          <td>0.8</td>
          <td>3</td>
          <td>30</td>
        </tr>

        <tr>
          <td>12</td>
          <td>8</td>
          <td>0.8</td>
          <td>3</td>
          <td>30</td>
        </tr>

        <tr>
          <td>12</td>
          <td>8</td>
          <td>0.8</td>
          <td>6.5</td>
          <td>10</td>
        </tr>

        <tr>
          <td>12</td>
          <td>8</td>
          <td>0.8</td>
          <td>6.5</td>
          <td>28</td>
        </tr>

        <tr>
          <td>12</td>
          <td>8</td>
          <td>0.8</td>
          <td>6.5</td>
          <td>28</td>
        </tr>

        <tr>
          <td>12</td>
          <td>8</td>
          <td>0.8</td>
          <td>10</td>
          <td>26</td>
        </tr>

        <tr>
          <td>12</td>
          <td>8</td>
          <td>0.8</td>
          <td>10</td>
          <td>26</td>
        </tr>
      </table>
    </div>

    <p>青砖茶</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/black/青砖茶.jpg" />
      <img src="../../assets/teaStandImg/black/青砖茶-成品.jpg" />
      <img src="../../assets/teaStandImg/black/青砖茶-汤色.jpg" />
      <img src="../../assets/teaStandImg/black/青砖茶-冲泡.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th>外形</th>
          <th>内质</th>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>茶梗（质量分数）%≤</th>
          <th>非茶类夹杂物（质量分数）%≤</th>
          <th>水浸出物（质量分数） ≥</th>
        </tr>

        <tr>
          <td>
            砖面光滑，棱角整齐，紧结平整，色泽青褐，压印纹理清晰，砖内无黑霉、白霉、青霉、等霉菌。
          </td>
          <td>香气纯正、滋味醇和、汤色橙红，叶底暗褐。</td>
          <td>12.0(计重水分 12.0％)</td>
          <td>8.5</td>
          <td>20.0（其中长于30mm的茶梗不得超过1.0%）</td>
          <td>0.2</td>
          <td>21</td>
        </tr>
      </table>
    </div>

    <p>沱茶</p>
    <div class="imgcontainer">
      <img src="../../assets/teaStandImg/black/沱茶.jpeg" />
      <img src="../../assets/teaStandImg/black/沱茶-汤色.jpeg" />
      <img src="../../assets/teaStandImg/black/沱茶-成品1.jpeg" />
      <img src="../../assets/teaStandImg/black/沱茶-包装.jpg" />
    </div>
    <div class="tablecontainer">
      <table>
        <tr>
          <th>外形</th>
          <th>内质</th>
          <th>水分（质量分数） ≤</th>
          <th>总灰分（质量分数） ≤</th>
          <th>茶梗（质量分数）%≤</th>
          <th>非茶类夹杂物（质量分数）%≤</th>
          <th>水浸出物（质量分数） ≥</th>
        </tr>

        <tr>
          <td>
            碗臼形，紧实、光滑，色泽墨绿、白毫显露，无黑霉、白霉、青霉、等霉菌。
          </td>
          <td>香气纯浓、汤色橙黄尚明，滋味浓醇，叶底嫩匀尚亮。</td>
          <td>9</td>
          <td>7</td>
          <td>3</td>
          <td>0.2</td>
          <td>36</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
p {
  font-size: 16px;
  margin: 20px;
  font-weight: bold;
  color: rgb(31, 83, 38);
  text-align: left;
}
table {
  border: 1px;

  border-collapse: collapse;
  width: 950px;
  text-align: center;
  margin-bottom: 20px;
}
.imgcontainer {
  display: flex;
  justify-content: space-around;
  width: 950px;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
}
img {
  width: 200px;
  height: 200px;
}

.tablecontainer {
  margin-bottom: 80px;
}
table,
table tr th,
table tr td {
  border: 1px solid #050505;
}
</style>
