<template>
  <div style="overflow: scroll; height: 750px">
    <p>绿茶</p>
    <table>
      <tr>
        <th colspan="8">炒青绿茶</th>
      </tr>
      <tr>
        <th>圆形</th>
        <th>类圆形</th>
        <th>直圆条形</th>
        <th>弯圆条形</th>
        <th>扁条形</th>
        <th>片形</th>
        <th>针形</th>
        <th>花形</th>
      </tr>
      <tr>
        <td>珠茶 泉岗輝白</td>
        <td>贡溪 涌溪火青 金龙玉珠</td>
        <td>珍眉 茗眉</td>
        <td>碧螺春 都匀毛尖</td>
        <td>西湖龙井 顶谷大方 大佛龙井 敬亭绿雪 天柱剑豪</td>
        <td>秀眉 绿片</td>
        <td>雨花茶 松针信阳毛尖 庐山云雾（特级）</td>
        <td>绿牡丹 菊花茶 舒城小兰花</td>
      </tr>
    </table>
    <table>
      <tr>
        <th colspan="6">烘青绿茶</th>
      </tr>
      <tr>
        <th>直圆条形</th>
        <th>条形</th>
        <th>扁条形</th>
        <th>尖形</th>
        <th>片形</th>
        <th>细粒形</th>
      </tr>
      <tr>
        <td>黄山毛峰 香芽 华山银毫</td>
        <td>岳西翠兰 霍山黄芽 九华佛茶 兰香</td>
        <td>香芽 六安瓜片（经揉捻的）</td>
        <td>太平猴魁（传统做法） 黄花云尖</td>
        <td>六安瓜片 太平猴魁</td>
        <td>绿碎茶</td>
      </tr>
    </table>
    <table>
      <tr>
        <th colspan="3">晒青绿茶</th>
      </tr>
      <tr>
        <th>粗条形</th>
        <th>条形</th>
        <th>圆形</th>
      </tr>
      <tr>
        <td>滇青 川青 陕青</td>
        <td>日本眉茶</td>
        <td>日本玉露茶</td>
      </tr>
    </table>
    <table>
      <tr>
        <th colspan="4">蒸青绿茶</th>
      </tr>
      <tr>
        <th>类圆形</th>
        <th>片形</th>
        <th>针形</th>
        <th>条形</th>
      </tr>
      <tr>
        <td>日本 印度贡熙和付熙</td>
        <td>日本碾茶 印度秀眉</td>
        <td>恩施玉露 日本玉露 日本煎茶</td>
        <td>茉莉花茶 白兰花茶 珠兰花茶</td>
      </tr>
    </table>
    <table>
      <tr>
        <th colspan="3">窨花绿茶</th>
        <th colspan="2">蒸压绿茶</th>
      </tr>
      <tr>
        <th>扁条形</th>
        <th>片形</th>
        <th>方形</th>
        <th>圆团形</th>
        <th>饼形</th>
      </tr>
      <tr>
        <td>珠兰大方</td>
        <td>茉莉绿片 珠兰三角片</td>
        <td>普洱方茶</td>
        <td>沱茶</td>
        <td>小饼茶</td>
      </tr>
    </table>
    <table>
      <tr>
        <th colspan="5">再加工绿茶</th>
      </tr>
      <tr>
        <th>茶粉</th>
        <th>速溶</th>
        <th>袋泡</th>
        <th>即饮</th>
        <th>茶汁</th>
      </tr>
      <tr>
        <td>超微绿茶粉 抹茶</td>
        <td>速溶绿茶</td>
        <td>袋泡绿茶</td>
        <td>绿茶饮料</td>
        <td>绿茶浓缩茶汁</td>
      </tr>
    </table>

    <div class="link-top"></div>
    <p>黄茶</p>
    <table>
      <tr>
        <th colspan="4">湿坯闷黄</th>
        <th colspan="3">干坯闷黄</th>
      </tr>
      <tr>
        <th>尖形</th>
        <th>扁条形</th>
        <th>条形</th>
        <th>曲形</th>
        <th>钩形</th>
        <th>针形</th>
        <th>雀舌形</th>
      </tr>
      <tr>
        <td>沩山毛尖</td>
        <td>蒙顶黄芽</td>
        <td>平阳黄汤 台湾黄茶 大叶青</td>
        <td>远安鹿苑 北港毛尖</td>
        <td>黄大茶</td>
        <td>君山银针</td>
        <td>霍山黄芽</td>
      </tr>
    </table>

    <div class="link-top"></div>

    <p>黑茶</p>
    <table>
      <tr>
        <th colspan="2">湿坯渥堆</th>
        <th colspan="7">干坯渥堆</th>
      </tr>
      <tr>
        <th>篓包形</th>
        <th>砖形</th>
        <th>散茶</th>
        <th>篓包形</th>
        <th>砖形</th>
        <th>饼形</th>
        <th>心形</th>
        <th>圆团形</th>
        <th>枕形</th>
      </tr>
      <tr>
        <td>天尖 贡尖 生尖</td>
        <td>黑砖茶 花砖茶 茯砖茶</td>
        <td>普洱散茶 老青茶 散装六堡茶</td>
        <td>六堡茶 方包茶 安茶</td>
        <td>康砖茶 四川茯砖茶 青砖茶 安化茯砖茶</td>
        <td>七子饼茶 六堡茶</td>
        <td>紧茶</td>
        <td>沱茶</td>
        <td>四川金尖</td>
      </tr>
    </table>

    <div class="link-top"></div>

    <p>白茶</p>
    <table>
      <tr>
        <th colspan="2">全萎凋</th>
        <th colspan="2">半萎凋</th>
      </tr>
      <tr>
        <th>芽形</th>
        <th>叶形</th>
        <th>芽形</th>
        <th>叶形</th>
      </tr>
      <tr>
        <td>政和白毫银针</td>
        <td>政和白牡丹</td>
        <td>白琳银针 土针 白云雪芽</td>
        <td>水吉白牡丹 贡眉 寿眉 仙台大白</td>
      </tr>
    </table>

    <div class="link-top"></div>

    <p>青茶(乌龙茶)</p>
    <table>
      <tr>
        <th colspan="2">跳动做青</th>
        <th colspan="3">摇动做青</th>
        <th colspan="2">做手做青</th>
        <th colspan="2">窨花青茶</th>
        <th colspan="3">再加工青茶</th>
      </tr>
      <tr>
        <th>蜻蜓头形</th>
        <th>粗条形</th>
        <th>条形</th>
        <th>虫牛头形</th>
        <th>粗眉形</th>
        <th>散形</th>
        <th>束形</th>
        <th>散形</th>
        <th>团块形</th>
        <th>袋泡</th>
        <th>即饮</th>
        <th>茶汁</th>
      </tr>
      <tr>
        <td>大红袍 铁罗汉 单丛奇种 名茶奇种 岩茶水仙</td>
        <td>闽北水仙 闽北乌龙</td>
        <td>台湾乌龙 台湾包种 饶平色种</td>
        <td>铁观音 黄金桂 色种 乌龙</td>
        <td>凤凰单丛 凤凰水仙 凤凰浪菜</td>
        <td>莲心 白毛猴 青龙井</td>
        <td>崇安赤石龙须</td>
        <td>桂花花茶 树兰花茶 栀子花茶</td>
        <td>龙图香茶</td>
        <td>袋泡青茶</td>
        <td>青茶饮料</td>
        <td>青茶浓缩茶汁</td>
      </tr>
    </table>

    <div class="link-top"></div>

    <p>红茶</p>
    <table>
      <tr>
        <th colspan="1">小种红茶</th>
        <th colspan="3">工夫红茶</th>
        <th colspan="3">红碎茶</th>
      </tr>
      <tr>
        <th>条形</th>
        <th>条形</th>
        <th>芽形</th>
        <th>片形</th>
        <th>条形</th>
        <th>颗粒形</th>
        <th>片末形</th>
      </tr>
      <tr>
        <td>正山小种 坦洋小种 政和小种</td>
        <td>祁门工夫 白琳工夫 坦洋工夫 台湾工夫 宁州工夫</td>
        <td>勐海金芽 金毫 红眉 君眉 金骏眉</td>
        <td>正花香 副花香</td>
        <td>白毫 橙黄白毫</td>
        <td>碎白毫 碎橙黄白毫 花碎橙黄白毫 碎1 碎2</td>
        <td>花香 碎橙黄白毫花香 白毫花香 碎末</td>
      </tr>
    </table>
    <table>
      <tr>
        <th colspan="1">窨花红茶</th>
        <th colspan="3">蒸压红茶</th>
        <th colspan="3">再加工红茶</th>
      </tr>
      <tr>
        <th>散形</th>
        <th>砖形</th>
        <th>团环</th>
        <th>茶粉</th>
        <th>袋泡</th>
        <th>速溶</th>
        <th>即饮</th>
        <th>茶汁</th>
      </tr>
      <tr>
        <td>玫瑰红茶 茉莉红茶</td>
        <td>米砖 末砖茶 小京砖茶</td>
        <td>红珠茶</td>
        <td>超微红茶粉</td>
        <td>袋泡红茶</td>
        <td>速溶绿茶</td>
        <td>红茶饮料</td>
        <td>红茶浓缩茶汁</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
p {
  font-size: 16px;
  margin: 20px;
  font-weight: bold;
  color: rgb(31, 83, 38);
  text-align: left;
}
table {
  border: 1px;
  border-collapse: collapse;
  width: 950px;
  text-align: center;
  margin-bottom: 20px;
}
table,
table tr th,
table tr td {
  border: 1px solid #050505;
}

.link-top {
  width: 950px;
  margin-top: 40px;
  border-top: solid green 2px;
  margin-bottom: 30px;
}
</style>
